import { useState } from "react";

function Shift(props) {
  return (
    <div className="created-row">
      <div className="creation-cell" style={{ width: "100%" }}>
        <div className="cell-name">
          <div>Shift {props.id}</div>
        </div>
        <div className="cell-control">
          <input
            type="text"
            placeholder="Start"
            value={props.start}
            onChange={(e) =>
              props.dataChanged(props.id - 1, "start", e.target.value)
            }
          ></input>
        </div>
        <div className="cell-control">
          <input
            type="text"
            placeholder="End"
            value={props.end}
            onChange={(e) =>
              props.dataChanged(props.id - 1, "end", e.target.value)
            }
          ></input>
        </div>
        <div
          className="cell-name"
          style={{ width: "10%" }}
          onClick={() => props.delete(props.id - 1)}
        >
          <i
            className="fa-solid fa-close"
            style={{ fontSize: "2rem", color: "red" }}
          ></i>
        </div>
      </div>
    </div>
  );
}

export default Shift;
