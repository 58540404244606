import { useEffect, useState } from "react";
import { config } from "../config";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Typography } from "@mui/material";
import { Fullscreen } from "@mui/icons-material";
import UserEdit from "./UserEdit";
import QualificationEdit from "./QualificationEdit";

function QualificationMgmt(props) {
  const [tableData, setTableData] = useState({ header: [], rows: [] });
  const [toggleEdit, setToggleEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  // const [users, setUsers] = useState([]);
  // const [newUser, setNewUser] = useState(false);
  var cols = ["User", "Qualified Areas"];
  var keys = ["fullName", "areas"];

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUsers() {
    props.raiseAlert("loading", "start");
    fetch(config.apiUrl + "users/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("loading", "end");
        props.raiseAlert("green", "Fetched Users!");
        // setUsers(actualData);
        setTableData({
          rows: actualData
            .filter((aD) => aD["username"] !== "admin")
            .map((aD) => {
              let data = { ...aD };
              data["id"] = aD.id;
              data["fullName"] = aD.fullName;
              data["areaIds"] = aD.area.map((a) => a.id).join(",");
              data["areas"] = aD.area.map((a) => a.name).join(",");
              return data;
            }),
          header: cols.map((element, inx) => {
            return {
              accessorKey: keys[inx],
              header: element,
            };
          }),
        });
      });
  }

  function handleRowClick(user) {
    // setNewUser(true);
    setSelectedUser(user.original);
    setToggleEdit(true);
  }

  function closeWindow(check) {
    setToggleEdit(check);
    getUsers();
  }

  function addANewUser() {
    // setNewUser(true);
    setToggleEdit(true);
    setSelectedUser((prev) => {
      return {
        id: "",
        areaIds: "",
      };
    });
  }

  return (
    <div className="f-dtl-container">
      <div className="f-table">
        <MaterialReactTable
          columns={tableData.header}
          data={tableData.rows}
          enableStickyHeader
          enableStickyFooter
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
              <Typography
                variant="h6"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "Poppins",
                  fontSize: "1.8rem",
                  alignSelf: "center",
                }}
              >
                Qualification
              </Typography>{" "}
              <div className="create-btn-n" onClick={() => addANewUser()}>
                Add
              </div>
            </Box>
          )}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box>
              <IconButton onClick={() => handleRowClick(row)}>
                <Fullscreen />
              </IconButton>
            </Box>
          )}
          muiTableHeadCellFilterTextFieldProps={
            config.mrtStyle.muiTableHeadCellFilterTextFieldProps
          }
          muiTableContainerProps={config.mrtStyle.muiTableContainerProps}
          initialState={{
            density: "compact",
            columnVisibility: { id: false },
            pagination: {
              pageSize: 20,
              pageIndex: 0,
            },
          }}
          muiTableHeadCellColumnActionsButtonProps={
            config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
          }
          muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
          muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
          muiTableBodyProps={config.mrtStyle.muiTableBodyProps}
        ></MaterialReactTable>
      </div>
      {toggleEdit && selectedUser != {} && (
        <QualificationEdit
          raiseAlert={props.raiseAlert}
          user={selectedUser}
          closeWindow={closeWindow}
          users={tableData.rows}
        ></QualificationEdit>
      )}
    </div>
  );
}

export default QualificationMgmt;
