import { useState } from "react";
import "./Platform.css";
import SiteHierarchyMgmt from "./SiteHierarchyMgmt";

function SiteHierarchy(props) {
  const heads = [
    "Site",
    "Plants",
    "Manufacturing Lines",
    "Areas",
    "Equipments",
  ];
  const [sites, setSites] = useState([]);
  const [selectedHead, setSelectedHead] = useState("Site");

  return (
    <div className="f-dtl-container">
      <div className="f-dtl-head">
        <div className="f-dtl-name">Site Hierarchy</div>
      </div>
      <div className="f-setting-table">
        <div className="f-head">
          {heads.map((head, inx) => {
            return (
              <div
                className={
                  "f-head-in " +
                  (selectedHead === head ? "f-head-in-selected" : "")
                }
                key={inx}
                onClick={() => setSelectedHead(head)}
              >
                {head}
              </div>
            );
          })}
        </div>
        <SiteHierarchyMgmt
          raiseAlert={props.raiseAlert}
          type={selectedHead}
          key={selectedHead}
        ></SiteHierarchyMgmt>
      </div>
    </div>
  );
}

export default SiteHierarchy;
