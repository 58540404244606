import { useEffect, useState } from "react";
import { config } from "../config";
import { Bar, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { MaterialReactTable } from "material-react-table";
import { Box, Typography, Button } from "@mui/material";

Chart.register({
  id: "custom_canvas_background_color",
  beforeDraw: (chart) => {
    if (
      chart.config.options.chartArea &&
      chart.config.options.chartArea.backgroundColor
    ) {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  },
});

function SkillAnalysis(props) {
  const [areas, setAreas] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState([]);
  const [mfgLines, setMfgLines] = useState([]);
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgLine, setMfgLine] = useState();
  const [site, setSite] = useState();
  const [plant, setPlant] = useState();
  const [shift, setShift] = useState();
  var cols = [
    "Area",
    "Category",
    "Skill",
    "Avg. Requirement",
    "Avg. Availability",
    "Surplus Indicator",
  ];
  var keys = ["area", "category", "skill", "req", "avl", "sur"];
  const [header, setHeader] = useState(
    cols.map((element, inx) => {
      return {
        accessorKey: keys[inx],
        header: element,
      };
    })
  );
  const [tableData, setTableData] = useState({
    header: [],
    rows: [
      {
        area: "Pune",
        category: "FTE",
        skill: "L1OP1",
        req: 54,
        avl: 59,
        sur: "Y",
      },
      {
        area: "Dahod",
        category: "FTE",
        skill: "L1OP1",
        req: 50,
        avl: 48,
        sur: "N",
      },
    ],
  });

  useEffect(() => {
    getItems("sites");
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") {
          setSites(actualData);
          getItems("plants");
        } else if (what === "plants") {
          setPlants(actualData);
          getItems("mfglines");
        } else {
          setMfgLines(actualData);
          getMfgLine(JSON.parse(localStorage.getItem("user"))["department"]);
        }
      });
  }

  function getMfgLine(mfgLine) {
    fetch(config.apiUrl + "mfglines/" + mfgLine + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched " + mfgLine + "!");
        setSite(actualData["plant"]["site"]["id"]);
        setPlant(actualData["plant"]["id"]);
        setMfgLine(actualData["id"]);
        getAreas(actualData["id"]);
      })
      .catch((err) => {
        setMfgLine(null);
      });
  }

  function getAreas(mL) {
    fetch(config.apiUrl + "areas/mfgline/" + mL + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Areas!");
        setAreas(actualData);
        // getProductionPlans(mL, actualData);
      });
  }

  function filterClicked() {
    if (site > 0) {
      if (plant > 0) {
        if (mfgLine > 0) {
          getAreas(mfgLine);
        } else {
          props.raiseAlert("red", "Please select a Manufacturing Line!");
        }
      } else {
        props.raiseAlert("red", "Please select a Plant!");
      }
    } else {
      props.raiseAlert("red", "Please select a Site!");
    }
  }

  return (
    <div className="f-dtl-container">
      <div
        className="created-row"
        style={{ width: "95%", margin: "2rem 0rem" }}
      >
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={site}
              onChange={(e) => {
                console.log(e.target);
                setSite(e.target.value);
              }}
            >
              <option value={-1}>Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Mfg. Line</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ flexGrow: "1" }}>
          <div className="btn create-btn-n" onClick={filterClicked}>
            Filter
          </div>
        </div>
      </div>
      <div className="f-in-table-ul">
        <div
          className="c-line"
          style={{
            height: "50vh",
          }}
        >
          <div
            className="ch-hd"
            style={{
              height: "5vh",
              display: "flex",
              alignItems: "center",
              width: "98%",
            }}
          >
            <div className="ch-selection-r">Skill Gap Analysis</div>
            <div className="grow"></div>
          </div>
          <div style={{ width: "100%", height: "45vh" }}>
            <Bar
              options={config.bOptions}
              data={{
                labels: ["A", "B", "C"],
                datasets: [
                  {
                    label: "Dataset 1",
                    data: [1, 2, 3],
                    backgroundColor: config.borderColors[3] + "ee",
                    borderColor: config.borderColors[3],
                    cubicInterpolationMode: "monotone",
                    tension: 0.4,
                    stack: "Stack-1",
                  },
                  {
                    label: "Dataset 2",
                    data: [3, 1, 1],
                    backgroundColor: config.borderColors[4] + "ee",
                    borderColor: config.borderColors[4],
                    cubicInterpolationMode: "monotone",
                    tension: 0.4,
                    stack: "Stack-1",
                  },
                  {
                    label: "Dataset 3",
                    data: [4, 4, 4],
                    backgroundColor: config.borderColors[1] + "ee",
                    borderColor: config.borderColors[1],
                    cubicInterpolationMode: "monotone",
                    tension: 0.4,
                    stack: "Stack-2",
                  },
                  {
                    label: "Dataset 4",
                    data: [1, 1, 1],
                    backgroundColor: config.borderColors[2] + "ee",
                    borderColor: config.borderColors[2],
                    cubicInterpolationMode: "monotone",
                    tension: 0.4,
                    stack: "Stack-2",
                  },
                ],
              }}
            ></Bar>
          </div>
        </div>
        <div
          className="c-line"
          style={{
            maxHeight: "50vh",
            minHeight: "10vh",
            backgroundColor: "#edededa5",
            padding: "1rem 0rem",
          }}
        >
          <MaterialReactTable
            columns={header}
            data={tableData.rows}
            enableStickyHeader
            enableStickyFooter
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  // p: ".4rem",
                  // backgroundColor: "#ededed",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "1.8rem",
                    alignSelf: "center",
                  }}
                >
                  Cross Skill-Area Utilization
                </Typography>{" "}
              </Box>
            )}
            muiTableHeadCellColumnActionsButtonProps={
              config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
            }
            muiTableHeadCellFilterTextFieldProps={
              config.mrtStyle.muiTableHeadCellFilterTextFieldProps
            }
            initialState={{
              density: "compact",
              columnVisibility: { id: false },
              pagination: {
                pageSize: 20,
                pageIndex: 0,
              },
            }}
            muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
            muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
            muiBottomToolbarProps={{
              sx: {
                padding: "0.8rem 0",
              },
            }}
            muiTopToolbarProps={{
              sx: { backgroundColor: "#edededa5" },
            }}
          ></MaterialReactTable>
        </div>
        <div
          className="c-line"
          style={{
            maxHeight: "100vh",
          }}
        >
          <div
            className="ch-hd"
            style={{
              height: "5vh",
              display: "flex",
              width: "98%",
              alignItems: "center",
            }}
          >
            <div className="ch-selection-r">Training Suggestions</div>
            <div className="grow"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkillAnalysis;
