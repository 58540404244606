import { useEffect, useState } from "react";
import "./Platform.css";
import { config } from "./../config";

function AreasMgmt(props) {
  const [id, setId] = useState(props.area.id);
  const [name, setName] = useState(props.area.name);
  const [code, setCode] = useState(props.area.code);
  const [type, setType] = useState(props.area.type);
  const [mfgLine, setMfgLine] = useState(
    props.area.mfgLine != undefined ? props.area.mfgLine.id : props.area.mfgLine
  );
  const [plant, setPlant] = useState(
    props.area.mfgLine != undefined
      ? props.area.mfgLine.plant.id
      : props.area.plant
  );
  const [site, setSite] = useState(
    props.area.mfgLine != undefined
      ? props.area.mfgLine.plant.site.id
      : props.area.site
  );
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgLines, setMfgLines] = useState([]);

  useEffect(() => {
    getItems("sites");
    getItems("plants");
    getItems("mfglines");
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") setSites(actualData);
        else if (what === "plants") setPlants(actualData);
        else setMfgLines(actualData);
      });
  }

  function saveArea() {
    let area = {};
    area["id"] = id;
    area["name"] = name;
    area["code"] = code;
    area["type"] = type;
    area["mfgLine"] = { id: mfgLine };
    console.log(area);
    save(area);
  }

  function save(area) {
    fetch(config.apiUrl + "areas/", {
      method: id > 0 ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(area),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Mfg. Line updated!");
        props.goBack();
      });
  }

  return (
    <div className="f-sh-dtl-container">
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Name</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Code</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Type</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={type}
              onChange={(e) => setType(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Site</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Plant</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Mfg. Line</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: ".4rem",
        }}
      >
        <div className="create-btn" onClick={saveArea}>
          Save Area
        </div>
      </div>
    </div>
  );
}

export default AreasMgmt;
