import { useState } from "react";
import { config } from "../config";
import AuditMgmt from "./AuditMgmt";
import DepartmentMgmt from "./DepartmentMgmt";
import "./Platform.css";
import RoleMgmt from "./RoleMgmt";
import SettingsMgmt from "./SettingsMgmt";
import UserMgmt from "./UserMgmt";
import SiteHierarchy from "./SiteHierarchy";
import ManningNorms from "./ManningNorms";
import ProductionPlan from "./ProductionPlan";
import ProductMaster from "./ProductMaster";

function Campus(props) {
  const [itemClicked, setItemClicked] = useState("");

  return (
    <div className="dashboard-container">
      <div className="u-d-container">
        <div className="u-menu p-menu-sidebar">
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("sitehierarchy")}
          >
            <div
              className={
                itemClicked === "sitehierarchy"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-sitemap"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Site Hierarchy</div>
          </div>

          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("manningnorms")}
          >
            <div
              className={
                itemClicked === "manningnorms"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-users-viewfinder"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Manning Norms</div>
          </div>
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("productmaster")}
          >
            <div
              className={
                itemClicked === "productmaster"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-pills"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Product Master</div>
          </div>
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("prodplan")}
          >
            <div
              className={
                itemClicked === "prodplan"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-scroll"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Production Plan</div>
          </div>
        </div>
        {itemClicked === "sitehierarchy" && (
          <SiteHierarchy raiseAlert={props.raiseAlert}></SiteHierarchy>
        )}
        {itemClicked === "manningnorms" && (
          <ManningNorms raiseAlert={props.raiseAlert}></ManningNorms>
        )}
        {itemClicked === "productmaster" && (
          <ProductMaster raiseAlert={props.raiseAlert}></ProductMaster>
        )}
        {itemClicked === "prodplan" && (
          <ProductionPlan raiseAlert={props.raiseAlert}></ProductionPlan>
        )}
      </div>
    </div>
  );
}

export default Campus;
