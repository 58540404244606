import { useState } from "react";
import { config } from "../config";
import AuditMgmt from "./AuditMgmt";
import DepartmentMgmt from "./DepartmentMgmt";
import "./Platform.css";
import RoleMgmt from "./RoleMgmt";
import SettingsMgmt from "./SettingsMgmt";
import UserMgmt from "./UserMgmt";
import SiteHierarchy from "./SiteHierarchy";
import ManningNorms from "./ManningNorms";
import ProductionPlan from "./ProductionPlan";
import ProductMaster from "./ProductMaster";
import SkillMatrixMgmt from "./SkillMatrixMgmt";
import QualificationMgmt from "./QualificationMgmt";
import AttendanceMgmt from "./AttendanceMgmt";

function Employees(props) {
  const [itemClicked, setItemClicked] = useState("");

  return (
    <div className="dashboard-container">
      <div className="u-d-container">
        <div className="u-menu p-menu-sidebar">
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("user")}
          >
            <div
              className={
                itemClicked === "user"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-users"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Users</div>
          </div>

          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("skillmatrix")}
          >
            <div
              className={
                itemClicked === "skillmatrix"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-person-circle-check"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Skill Matrix</div>
          </div>
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("qualification")}
          >
            <div
              className={
                itemClicked === "qualification"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-address-card"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Qualification</div>
          </div>
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("attendance")}
          >
            <div
              className={
                itemClicked === "attendance"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-calendar-check"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Attendance</div>
          </div>
        </div>
        {itemClicked === "user" && (
          <UserMgmt raiseAlert={props.raiseAlert}></UserMgmt>
        )}
        {itemClicked === "skillmatrix" && (
          <SkillMatrixMgmt raiseAlert={props.raiseAlert}></SkillMatrixMgmt>
        )}
        {itemClicked === "qualification" && (
          <QualificationMgmt raiseAlert={props.raiseAlert}></QualificationMgmt>
        )}
        {itemClicked === "attendance" && (
          <AttendanceMgmt raiseAlert={props.raiseAlert}></AttendanceMgmt>
        )}
      </div>
    </div>
  );
}

export default Employees;
