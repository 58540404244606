import { useEffect, useState, useRef } from "react";
import { config } from "../config";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { Fullscreen } from "@mui/icons-material";
import UserEdit from "./UserEdit";
import SkillMatrixEdit from "./SkillMatrixEdit";
import {
  GetApp,
  Upload,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

function SkillMatrixMgmt(props) {
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const [tableData, setTableData] = useState({ header: [], rows: [] });
  const [toggleEdit, setToggleEdit] = useState(false);
  const [selectedSM, setSelectedSM] = useState({});
  const [sMs, setSMs] = useState([]);
  var cols = [
    "User",
    "Equipment ID",
    "Equipment",
    "Skill",
    "Default Allocation",
  ];
  var keys = ["user", "equipmentId", "equipment", "skill", "defaultAllocation"];

  useEffect(() => {
    getSkillMatrix();
  }, []);

  function getSkillMatrix() {
    props.raiseAlert("loading", "start");
    fetch(config.apiUrl + "skill-matrix/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("loading", "end");
        props.raiseAlert("green", "Fetched Skill Matrix!");
        setSMs(actualData);
        setTableData({
          rows: actualData.map((aD) => {
            let data = { ...aD };
            data["id"] = aD.id;
            data["user"] = aD.fullName;
            data["equipmentId"] = aD.equipmentId;
            data["equipment"] = aD.equipmentName;
            data["skill"] = aD.skill;
            data["defaultAllocation"] = aD.defaultAllocation;
            return data;
          }),
          header: cols.map((element, inx) => {
            return {
              accessorKey: keys[inx],
              header: element,
              Cell: ({ renderedCellValue, row }) => {
                if (element === "Default Allocation") {
                  if (renderedCellValue) return <CheckBox></CheckBox>;
                  else return <CheckBoxOutlineBlank></CheckBoxOutlineBlank>;
                } else {
                  return renderedCellValue;
                }
              },
            };
          }),
        });
      });
  }

  function getTemplate() {
    console.log("Getting template");
    const a = document.createElement("a");
    a.href = config.apiUrl + "skill-matrix/template/";
    a.download = (config.apiUrl + "skill-matrix/template/").split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function handleFileChange(e) {
    console.log("Handling file change");
    if (e.target.files) {
      const f = new FormData();
      f.append("file", e.target.files[0]);
      fetch(config.apiUrl + "skill-matrix/upload/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        },
        body: f,
      }).then((response) => {
        if (response.ok) {
          getSkillMatrix();
          props.raiseAlert("green", "Data Uploaded");
        }
      });
    }
  }

  function handleRowClick(sm) {
    // setNewUser(true);
    setSelectedSM(sMs.filter((s) => s.id == sm.original.id)[0]);
    setToggleEdit(true);
  }

  function closeWindow(check) {
    setToggleEdit(check);
    getSkillMatrix();
  }

  function addANewUser() {
    // setNewUser(true);
    setToggleEdit(true);
    setSelectedSM((prev) => {
      return {
        user: {},
        equipment: {},
        skill: "",
        defaultAllocation: false,
      };
    });
  }

  return (
    <div className="f-dtl-container">
      <div className="f-table">
        <MaterialReactTable
          columns={tableData.header}
          data={tableData.rows}
          enableStickyHeader
          enableStickyFooter
          muiTableHeadCellColumnActionsButtonProps={
            config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
          }
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
              <Typography
                variant="h6"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "Poppins",
                  fontSize: "1.8rem",
                  alignSelf: "center",
                }}
              >
                Skill Matrix
              </Typography>{" "}
              <div className="create-btn-n" onClick={() => addANewUser()}>
                Add
              </div>
              <div onClick={getTemplate} className="create-btn-n">
                Get Template
              </div>
              <div onClick={handleClick} className="create-btn-b">
                Upload
              </div>
            </Box>
          )}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box>
              <IconButton onClick={() => handleRowClick(row)}>
                <Fullscreen />
              </IconButton>
            </Box>
          )}
          muiTableHeadCellFilterTextFieldProps={
            config.mrtStyle.muiTableHeadCellFilterTextFieldProps
          }
          muiTableContainerProps={config.mrtStyle.muiTableContainerProps}
          initialState={{
            density: "compact",
            columnVisibility: { id: false },
            pagination: {
              pageSize: 20,
              pageIndex: 0,
            },
          }}
          muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
          muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
          muiTableBodyProps={config.mrtStyle.muiTableBodyProps}
          muiBottomToolbarProps={config.mrtStyle.muiBottomToolbarProps}
        ></MaterialReactTable>
      </div>
      {toggleEdit && selectedSM != {} && (
        <SkillMatrixEdit
          raiseAlert={props.raiseAlert}
          skillMatrix={selectedSM}
          closeWindow={closeWindow}
          users={tableData.rows}
        ></SkillMatrixEdit>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default SkillMatrixMgmt;
