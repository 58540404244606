import "./Platform.css";
import { config } from "../config";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";

function SkillMatrixEdit(props) {
  console.log(props);
  const [skillMatrix, setSkillMatrix] = useState(props.skillMatrix);
  const [users, setUsers] = useState([]);
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    props.raiseAlert("loading", "start");
    setUsers([
      ...new Map(
        props.users
          .map((u) => {
            return { id: u.userId, fullName: u.fullName };
          })
          .map((item) => [item["id"], item])
      ).values(),
    ]);
    // getUsers();
    getEquipmentsForArea(1);
  }, []);

  function getUsers() {
    fetch(config.apiUrl + "users/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        // props.raiseAlert("green", "Fetched Users!");
        setUsers(actualData.filter((aD) => aD["username"] !== "admin"));
        props.raiseAlert("loading", "end");
      });
  }

  function getQualification(empId) {}

  function getEquipmentsForArea(areaId) {
    fetch(config.apiUrl + "equipments/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("loading", "end");
        setEquipments(actualData);
      });
  }

  function skillMatrixChanged(what, value) {
    console.log(value);
    setSkillMatrix((prev) => {
      let toBeUpdated = { ...prev };
      if (what !== "final") {
        var obj = toBeUpdated;
        let splitWhat = what.split(".");
        for (var i = 0; i < splitWhat.length - 1; i++) {
          let prop = splitWhat[i];
          obj = obj[prop];
        }
        let finalProp = splitWhat[i];
        obj[finalProp] = value;
      }
      return toBeUpdated;
    });
  }

  function updateSkillMatrix() {
    let updatedSM = {};
    updatedSM["id"] = skillMatrix["id"];
    updatedSM["defaultAllocation"] = skillMatrix["defaultAllocation"];
    updatedSM["skill"] = skillMatrix["skill"];
    updatedSM["equipment"] = { id: skillMatrix["equipmentId"] };
    updatedSM["user"] = { id: skillMatrix["userId"] };

    fetch(config.apiUrl + "skill-matrix/", {
      method: skillMatrix.id == undefined ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(updatedSM),
    })
      .then((response) => {
        if (response.ok) {
          return true;
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Skill Matrix updated!");
        props.closeWindow(false);
      });
  }
  return (
    <div className="user-preview">
      <div className="viz-preview-details">
        <div className="viz-name">Edit Skill Matrix</div>
        <div className="grow"></div>
        <div className="close-icon">
          <i
            className="fa-solid fa-close"
            onClick={() => props.closeWindow(false)}
          ></i>
        </div>
      </div>
      <div className="created-container">
        <div className="created-row">
          <div className="creation-cell" style={{ width: "100%" }}>
            <div className="cell-name">
              <div>User</div>
            </div>
            <div className="cell-control">
              <Multiselect
                style={
                  skillMatrix.userId == undefined ||
                  skillMatrix.userId == null ||
                  skillMatrix.userId.length == 0
                    ? config.multiSelectStyle
                    : config.platformMultiSelectStyle
                }
                avoidHighlightFirstOption={true}
                onSelect={(e) => {
                  skillMatrixChanged("userId", e[0]["id"]);
                }}
                onRemove={(e) => skillMatrixChanged("userId", "")}
                selectedValues={users.filter(
                  (user) => user["id"] === skillMatrix["userId"]
                )}
                options={users}
                displayValue={"fullName"}
                // singleSelect={true}
                selectionLimit={1}
                closeOnSelect={true}
                hidePlaceholder={true}
              ></Multiselect>
            </div>
          </div>
        </div>
        <div className="created-row">
          <div className="creation-cell" style={{ width: "100%" }}>
            <div className="cell-name">
              <div>Equipment</div>
            </div>
            <div className="cell-control">
              <Multiselect
                style={
                  skillMatrix.equipmentId == undefined ||
                  skillMatrix.equipmentId == null ||
                  skillMatrix.equipmentId.length == 0
                    ? config.multiSelectStyle
                    : config.platformMultiSelectStyle
                }
                avoidHighlightFirstOption={true}
                onSelect={(e) => {
                  skillMatrixChanged("equipmentId", e[0]["id"]);
                }}
                onRemove={(e) => skillMatrixChanged("equipmentId", "")}
                selectedValues={equipments.filter(
                  (eq) => eq["id"] === skillMatrix["equipmentId"]
                )}
                options={equipments}
                displayValue={"id"}
                selectionLimit={1}
                closeOnSelect={true}
                hidePlaceholder={true}
              ></Multiselect>
            </div>
          </div>
        </div>
        <div className="created-row">
          <div className="creation-cell" style={{ width: "100%" }}>
            <div className="cell-name">
              <div>Skill Level</div>
            </div>
            <div className="cell-control">
              <select
                value={skillMatrix.skill}
                onChange={(e) => skillMatrixChanged("skill", e.target.value)}
              >
                <option value={""}>Select</option>
                {["L1OP1", "L2OP2", "OP1", "OP2", "P1", "P2", "L1HP1"].map(
                  (lvl, inx) => {
                    return (
                      <option key={inx} value={lvl}>
                        {lvl}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="created-row">
          <div className="creation-cell" style={{ width: "100%" }}>
            <div className="cell-name">
              <div>Default Allocation</div>
            </div>
            <div className={"cell-control"}>
              <input
                type="checkbox"
                value={skillMatrix.defaultAllocation}
                onChange={(e) =>
                  skillMatrixChanged("defaultAllocation", e.target.checked)
                }
                checked={skillMatrix.defaultAllocation}
              ></input>
            </div>
          </div>
        </div>
        <div className="btn-controls">
          <div onClick={updateSkillMatrix} className="create-btn margin-top-10">
            {skillMatrix.id == undefined ? "Add" : "Update"}
          </div>

          <div className="cancel-btn" onClick={() => props.closeWindow(false)}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkillMatrixEdit;
