import "./Platform.css";
import { useEffect, useMemo, useState, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { GetApp, Upload } from "@mui/icons-material";
import { config } from "../config";

function ProductMaster(props) {
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    getProductMaster();
  }, []);

  function getProductMaster() {
    fetch(config.apiUrl + "product-master/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Product Master!");
        setData(
          actualData.map((d) => {
            return {
              site: d.site.name,
              name: d.name,
              code: d.code,
              priority: d.priority,
              type: d.type,
            };
          })
        );
      });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "site",
        header: "Site",
      },
      {
        accessorKey: "name",
        header: "Product Name",
      },
      {
        accessorKey: "code",
        header: "Product Code",
      },
      {
        accessorKey: "priority",
        header: "Product Priority",
      },
      {
        accessorKey: "type",
        header: "Product Type",
      },
      //column definitions...
    ],
    []
  );

  function getTemplate() {
    console.log("Getting template");
    const a = document.createElement("a");
    a.href = config.apiUrl + "product-master/template/";
    a.download = (config.apiUrl + "product-master/template/").split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const table = useMaterialReactTable({
    columns,
    data,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
        <Typography
          variant="h6"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Poppins",
            fontSize: "1.8rem",
            alignSelf: "center",
          }}
        >
          Product Master
        </Typography>
        <div
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={getTemplate}
          className="create-btn-n"
        >
          Get Template
        </div>
        <div onClick={handleClick} className="create-btn-b">
          {<Upload />} Upload
        </div>
      </Box>
    ),
    muiTableContainerProps: config.mrtStyle.muiTableContainerProps,
    initialState: {
      density: "compact",
      columnVisibility: { id: false },
    },
    muiTableHeadCellProps: config.mrtStyle.muiTableHeadCellProps,
    muiColumnActionsButtonProps:
      config.mrtStyle.muiTableHeadCellColumnActionsButtonProps,
    muiTableBodyCellProps: config.mrtStyle.muiTableBodyCellProps,
    muiFilterTextFieldProps:
      config.mrtStyle.muiTableHeadCellFilterTextFieldProps,
    muiTableBodyProps: config.mrtStyle.muiTableBodyProps,
    // enableRowSelection: true,
    // getRowId: (row) => row.code,
    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // muiToolbarAlertBannerProps: isError
    //   ? {
    //       color: "error",
    //       children: "Error loading data",
    //     }
    //   : undefined,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    // rowCount,
    // state: {
    //   columnFilters,
    //   globalFilter,
    //   isLoading,
    //   pagination,
    //   showAlertBanner: isError,
    //   showProgressBars: isRefetching,
    //   sorting,
    // },
  });

  function handleFileChange(e) {
    console.log("Handling file change");
    if (e.target.files) {
      const f = new FormData();
      f.append("file", e.target.files[0]);
      fetch(config.apiUrl + "product-master/upload/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        },
        body: f,
      })
        .then((response) => {
          if (response.ok) {
            return null;
          } else {
            return response.text();
          }
        })
        .then((actualData) => {
          if (actualData != null)
            props.raiseAlert("red", "Error occurred : " + actualData, 5000);
          else {
            props.raiseAlert("green", "Data Uploaded");
            getProductMaster();
          }
        });
    }
  }

  return (
    <div className="f-dtl-container">
      <div className="f-table">
        <MaterialReactTable
          columns={columns}
          data={data}
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
              <Typography
                variant="h6"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "Poppins",
                  fontSize: "1.8rem",
                  alignSelf: "center",
                }}
              >
                Product Master
              </Typography>
              <div
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={getTemplate}
                className="create-btn-n"
              >
                Get Template
              </div>
              <div onClick={handleClick} className="create-btn-b">
                Upload
              </div>
            </Box>
          )}
          muiTableContainerProps={config.mrtStyle.muiTableContainerProps}
          initialState={{
            density: "compact",
            columnVisibility: { id: false },
            pagination: {
              pageSize: 20,
              pageIndex: 0,
            },
          }}
          muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
          muiColumnActionsButtonProps={
            config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
          }
          muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
          muiFilterTextFieldProps={
            config.mrtStyle.muiTableHeadCellFilterTextFieldProps
          }
          muiTableBodyProps={config.mrtStyle.muiTableBodyProps}
          muiBottomToolbarProps={config.mrtStyle.muiBottomToolbarProps}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}

export default ProductMaster;
