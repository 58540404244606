import "./Platform.css";
import { useEffect, useMemo, useState, useRef } from "react";
import { config } from "../config";
import { Chart } from "react-google-charts";
import Multiselect from "multiselect-react-dropdown";

const today = new Date();
var pD =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

function ProductionPlan(props) {
  const options = {
    tooltip: { trigger: "focus", isHtml: true },
    timeline: {
      rowLabelStyle: {
        fontName: "Poppins",
        color: "#012236",
        fontSize: window.screen.width < 1450 ? 13 : 14,
      },
      barLabelStyle: {
        fontName: "Poppins",
        fontSize: window.screen.width < 1450 ? 12 : 13,
      },
    },
    colors: config.borderColors,
  };
  const chartEvents = [
    {
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        chart.container.addEventListener("mousedown", (event) => {
          if (event.target.id !== "tt-btn") chart.setSelection([]);
        });
      },
      eventName: "ready",
    },
    {
      callback: ({ chartWrapper, google }) => {
        var chart = chartWrapper.getChart();
        const sel = chart.getSelection();
        if (sel.length > 0 && sel[0].row != null) {
          var tooltip = document.querySelector(
            ".google-visualization-tooltip:not([clone])"
          );
          //remove previous clone when there is any
          if (chart.ttclone && chart.ttclone.parentNode) {
            chart.ttclone.parentNode.removeChild(chart.ttclone);
          }
          //create a clone of the built-in tooltip
          chart.ttclone = tooltip.cloneNode(true);
          //create a custom attribute to be able to distinguish
          //built-in tooltip and clone
          chart.ttclone.setAttribute("clone", true);
          //inject clone into document
          tooltip.parentNode.insertBefore(chart.ttclone, chart.tooltip);
          const allNodes = eachNode(chart.ttclone);
          var ppNode = allNodes
            .filter((e) => e.nodeType === 1)
            .filter((e) => e.getAttribute("id") === "tt-id");
          var ppId = -1;
          var cData = chartWrapper.Bea.Wf[sel[0].row].c;
          console.log(pD);
          if (
            ppNode.length > 0 &&
            allNodes.filter((e) => e.textContent === "Remove").length > 0
          ) {
            ppId = ppNode[0].firstChild.wholeText;
            allNodes
              .filter((e) => e.textContent === "Remove")[0]
              .addEventListener(
                "click",
                (event) => {
                  event.stopPropagation();
                  removeProdPlan(ppId);
                },
                false
              );
          } else if (
            allNodes.filter((e) => e.textContent === "Add").length > 0
          ) {
            allNodes
              .filter((e) => e.textContent === "Add")[0]
              .addEventListener(
                "click",
                (event) => {
                  event.stopPropagation();
                  const cArea = areas.filter((a) => a.name === cData[0].v)[0];

                  const siteShift = cArea.mfgLine.plant.site.shifts.filter(
                    (s) =>
                      parseInt(s.start.split(":")[0]) ==
                        cData[4].v.getHours() &&
                      parseInt(s.start.split(":")[1]) == cData[4].v.getMinutes()
                  )[0];
                  setChosenPlan((prev) => {
                    let toBe = { ...prev };
                    return {
                      id: -1,
                      operation: "",
                      siteShift: { ...siteShift },
                      batchNumber: "",
                      priority: "",
                      area: {
                        id: cArea.id,
                        name: cArea.name,
                      },
                    };
                  });
                  if (products.length == 0) getProducts();
                  setShowAddDiag(!showAddDiag);
                },
                false
              );
          }

          function eachNode(rootNode, callback) {
            if (!callback) {
              const nodes = [];
              eachNode(rootNode, (node) => {
                nodes.push(node);
              });
              return nodes;
            }

            if (callback(rootNode) === false) {
              return false;
            }

            if (rootNode.hasChildNodes()) {
              for (const node of rootNode.childNodes) {
                if (eachNode(node, callback) === false) {
                  return;
                }
              }
            }
          }
        } else {
          if (chart.ttclone) {
            chart.ttclone.parentNode.removeChild(chart.ttclone);
          }
        }
      },
      eventName: "select",
    },
  ];
  const [products, setProducts] = useState([]);
  const [showAddDiag, setShowAddDiag] = useState(false);
  const [prodPlans, setProdPlans] = useState([]);
  const [uploadHist, setUploadHist] = useState([]);
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const [chosenPlan, setChosenPlan] = useState();
  const [areas, setAreas] = useState([]);
  const [planDate, setPlanDate] = useState(
    today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)
  );
  const [data, setData] = useState([]);
  const [mfgLines, setMfgLines] = useState([]);
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgLine, setMfgLine] = useState();
  const [site, setSite] = useState();
  const [plant, setPlant] = useState();
  const [history, setHistory] = useState(false);

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    getItems("sites");
  }, []);

  function getToolTip(id, pCode, batch, priority, start, pDate) {
    console.log(pDate);
    console.log(pD);
    const ps = pDate.split("-");
    var diffMins =
      (new Date() -
        new Date(
          parseInt(ps[0]),
          parseInt(ps[1]) - 1,
          parseInt(ps[2]),
          start.split(":")[0],
          start.split(":")[1]
        )) /
      (1000 * 60);
    console.log(new Date());
    console.log(
      new Date(
        parseInt(ps[0]),
        parseInt(ps[1]) - 1,
        parseInt(ps[2]),
        start.split(":")[0],
        start.split(":")[1]
      )
    );
    console.log(diffMins);
    if (id > 0)
      return (
        '<div class="pp-tt">' +
        '<div id="tt-id">' +
        id +
        "</div>" +
        '<div class="tt-line"><div class="tt-head">Operation :&nbsp;</div><div class="tt-val">' +
        pCode +
        "</div></div>" +
        '<div class="tt-line"><div class="tt-head">Batch :&nbsp;</div><div class="tt-val">' +
        batch +
        "</div></div>" +
        '<div class="tt-line"><div class="tt-head">Priority :&nbsp;</div><div class="tt-val">' +
        priority +
        "</div></div>" +
        (diffMins < -30
          ? '<div class="tt-line"><div class="btn create-btn-bl" id="tt-btn">Remove</div></div>'
          : '<div class="tt-msg">Production Plan Frozen</div>') +
        "</div>"
      );
    else {
      return (
        '<div class="pp-tt">' +
        (diffMins < -30
          ? '<div class="tt-line"><div class="btn create-btn-bl" id="tt-btn">Add</div></div>'
          : '<div class="tt-msg">Production Plan Frozen</div>') +
        "</div>"
      );
    }
  }

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") {
          setSites(actualData);
          getItems("plants");
        } else if (what === "plants") {
          setPlants(actualData);
          getItems("mfglines");
        } else {
          setMfgLines(actualData);
          getMfgLine(JSON.parse(localStorage.getItem("user"))["department"]);
        }
      });
  }

  function getMfgLine(mfgLine) {
    fetch(config.apiUrl + "mfglines/" + mfgLine + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched " + mfgLine + "!");
        setSite(actualData["plant"]["site"]["id"]);
        setPlant(actualData["plant"]["id"]);
        setMfgLine(actualData["id"]);
        getAreas(actualData["id"]);
      })
      .catch((err) => {
        setMfgLine(null);
      });
  }

  function getUploadHistory() {
    fetch(config.apiUrl + "upload-history/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Upload History!");
        setUploadHist(actualData);
      });
  }

  function getProducts() {
    fetch(config.apiUrl + "product-master/basics/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Products!");
        setProducts(actualData);
      });
  }

  function filterClicked() {
    if (site > 0) {
      if (plant > 0) {
        if (mfgLine > 0) {
          if (planDate != null) {
            getAreas(mfgLine);
          } else {
            props.raiseAlert("red", "Please select a Plan Date!");
          }
        } else {
          props.raiseAlert("red", "Please select a Manufacturing Line!");
        }
      } else {
        props.raiseAlert("red", "Please select a Plant!");
      }
    } else {
      props.raiseAlert("red", "Please select a Site!");
    }
  }

  function getAreas(mL) {
    fetch(config.apiUrl + "areas/mfgline/" + mL + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Areas!");
        setAreas(actualData);
        getProductionPlans(mL, actualData);
      });
  }

  function getProductionPlans(mL, areas) {
    props.raiseAlert("loading", "start");
    fetch(
      config.apiUrl +
        "production-plans/mfgline_date/?mfgLineId=" +
        mL +
        "&date=" +
        pD,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Production Plans!");
        setData((prev) => {
          const t = actualData
            .filter((d) => d.status !== "REMOVED")
            .map((d) => {
              return {
                areaCode: d.area.code,
                areaName: d.area.name,
                start: d.siteShift.start,
                end: d.siteShift.end,
                sid: d.siteShift.id,
                pCode: d.operation,
                batch: d.batchNumber,
                priority: d.priority,
                id: d.id,
              };
            });
          var d = [
            [
              { type: "string", id: "Room" },
              { type: "string", id: "Name" },
              {
                role: "tooltip",
                type: "string",
                p: { html: true, trigger: "selection" },
              },
              {
                role: "style",
                type: "string",
              },
              { type: "date", id: "Start" },
              { type: "date", id: "End" },
            ],
          ];
          areas.forEach((a) => {
            var s = a.mfgLine.plant.site.shifts;
            s.forEach((shift) => {
              // console.log(shift);
              var rr = t.filter(
                (tt) => tt.areaCode === a.code && tt.sid == shift.id
              );
              var r;
              if (rr.length > 0) {
                r = rr[0];
              } else {
                r = {
                  pCode: "",
                  id: -1,
                  batch: "",
                  priority: "",
                  start: shift.start,
                  end: shift.end,
                };
              }
              d.push([
                a.name,
                window.screen.width < 1450
                  ? r.pCode.length > 30
                    ? r.pCode.substring(0, 30) + "..."
                    : r.pCode
                  : r.pCode,
                getToolTip(
                  r.id,
                  r.pCode,
                  r.batch,
                  r.priority,
                  r.start,
                  planDate
                ),
                r.id == -1
                  ? "fill-color:white; color: #c25556; stroke-width: 3"
                  : "",
                new Date(
                  0,
                  0,
                  0,
                  parseInt(r.start.split(":")[0]),
                  parseInt(r.start.split(":")[1])
                ),
                new Date(
                  0,
                  0,
                  0,
                  parseInt(r.end.split(":")[0]),
                  parseInt(r.end.split(":")[1])
                ),
              ]);
            });
          });

          return d;
        });

        props.raiseAlert("loading", "end");
        // setProdPlans(actualData);
      });
  }

  function handleFileChange(e) {
    if (e.target.files) {
      const f = new FormData();
      f.append("file", e.target.files[0]);
      fetch(config.apiUrl + "production-plans/upload/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        },
        body: f,
      })
        .then((response) => {
          if (response.ok) {
            return null;
          } else {
            return response.text();
          }
        })
        .then((actualData) => {
          if (actualData != null)
            props.raiseAlert("red", "Error occurred : " + actualData, 5000);
          else {
            getAreas(mfgLine);
            props.raiseAlert("green", "Data Uploaded");
          }
        });
    }
  }

  function getTemplate() {
    if (mfgLine > 0) {
      const a = document.createElement("a");
      a.href = config.apiUrl + "production-plans/template/" + mfgLine + "/";
      a.download = (
        config.apiUrl +
        "production-plans/template/" +
        mfgLine +
        "/"
      )
        .split("/")
        .pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      props.raiseAlert("red", "Please select a Manufacturing Line!");
    }
  }

  function downloadAttachment(historyId, fileName) {
    fetch(config.apiUrl + "attachment/download/" + historyId, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  }

  function handleHistoryClick() {
    if (!history) {
      getUploadHistory();
    }
    setHistory(!history);
  }

  const removeProdPlan = (id) => {
    fetch(config.apiUrl + "production-plans/" + id + "/", {
      method: "DELETE",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        getAreas(mfgLine);
        props.raiseAlert("green", "Production Plan Updated!");
      }
    });
  };

  function addProdPlan() {
    var pp = chosenPlan;
    delete pp["id"];
    pp["planDate"] = planDate;
    pp["status"] = "RE-PLANNED";
    fetch(config.apiUrl + "production-plans/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(pp),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Areas!");
        getAreas(mfgLine);
        setChosenPlan((prev) => {
          let toBe = { ...prev };
          return {};
        });
        setShowAddDiag(!showAddDiag);
      });
  }

  return (
    <div className="f-dtl-container">
      <div
        className="created-row"
        style={{ width: "95%", margin: "2rem 0rem" }}
      >
        <div className="creation-cell" style={{ width: "19%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "19%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "19%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Select a Mfg. Line</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "19%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={planDate}
              onChange={(e) => {
                setPlanDate(e.target.value);
                pD = e.target.value;
              }}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "9%" }}>
          <div className="btn create-btn-n" onClick={filterClicked}>
            Filter
          </div>
        </div>
        <div className="creation-cell" style={{ width: "5%" }}>
          <div className="btn create-btn-b" onClick={handleClick}>
            <i className="fa-solid fa-upload"></i>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "5%" }}>
          <div className="btn create-btn-b" onClick={getTemplate}>
            <i className="fa-solid fa-file-arrow-down"></i>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "5%" }}>
          <div className="btn create-btn-bl" onClick={handleHistoryClick}>
            <i className="fa-solid fa-clock-rotate-left"></i>
          </div>
        </div>
      </div>
      <div className="f-table">
        {data.length > 0 && (
          <Chart
            chartType="Timeline"
            data={data}
            width="98%"
            height="80vh"
            options={options}
            chartEvents={chartEvents}
          />
        )}
      </div>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {history && (
        <>
          <div className="h-container">
            <div className="viz-preview-details">
              <div className="viz-name">Upload History</div>
              <div className="grow"></div>
              <div className="close-icon">
                <i
                  className="fa-solid fa-close"
                  onClick={handleHistoryClick}
                ></i>
              </div>
            </div>
            <div className="h-m-container">
              {uploadHist.map((h, inx) => (
                <div className="h-items" key={inx}>
                  <div
                    className="d-icon"
                    onClick={() => downloadAttachment(h.id, h.fileName)}
                  >
                    <i className="fa-solid fa-download"></i>
                  </div>
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "var(--green)",
                      }}
                    >
                      {h.createdBy}
                    </div>
                    <div>uploaded</div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "dodgerblue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => downloadAttachment(h.id, h.fileName)}
                    >
                      {h.fileName}
                    </div>
                    <div>at</div>
                    <div style={{ fontStyle: "italic", color: "var(--dark)" }}>
                      {h.createDt}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className={"pp-modal " + (showAddDiag ? " " : " close-flex")}>
        <div className="viz-preview-details">
          <div className="viz-name">Production Plan</div>
          <div className="grow"></div>
          <div className="close-icon">
            <i
              className="fa-solid fa-close"
              onClick={() => {
                setChosenPlan((prev) => {
                  let toBe = { ...prev };
                  return {};
                });
                setShowAddDiag(!showAddDiag);
              }}
            ></i>
          </div>
        </div>
        <div className="created-container">
          <div className="created-row">
            <div className="creation-cell" style={{ width: "100%" }}>
              <div className="cell-name">
                <div>Product/Operation</div>
              </div>
              <div className="cell-control">
                <Multiselect
                  style={
                    chosenPlan?.operation === ""
                      ? config.multiSelectStyle
                      : config.platformMultiSelectStyle
                  }
                  onSelect={(e) => {
                    setChosenPlan((p) => {
                      let prev = { ...p };
                      prev["operation"] = e[0]["name"];
                      return prev;
                    });
                  }}
                  onRemove={(e) =>
                    setChosenPlan((p) => {
                      let prev = { ...p };
                      prev["operation"] = "";
                      return prev;
                    })
                  }
                  selectedValues={products.filter(
                    (p) => p["name"] === chosenPlan?.operation
                  )}
                  options={products}
                  displayValue={"name"}
                  selectionLimit={1}
                  hidePlaceholder
                ></Multiselect>
              </div>
            </div>
          </div>
          <div className="created-row">
            <div className="creation-cell" style={{ width: "100%" }}>
              <div className="cell-name">
                <div>Area</div>
              </div>
              <div className="cell-control r-e-text-area">
                <input
                  type="text"
                  value={chosenPlan?.area?.name}
                  disabled
                ></input>
              </div>
            </div>
          </div>
          <div className="created-row">
            <div className="creation-cell" style={{ width: "50%" }}>
              <div className="cell-name">
                <div>Batch Number</div>
              </div>
              <div className="cell-control r-e-text-area">
                <input
                  type="text"
                  value={chosenPlan?.batchNumber}
                  onChange={(e) =>
                    setChosenPlan((p) => {
                      let prev = { ...p };
                      prev["batchNumber"] = e.target.value;
                      return prev;
                    })
                  }
                ></input>
              </div>
            </div>
            <div className="creation-cell" style={{ width: "50%" }}>
              <div className="cell-name">
                <div>Priority</div>
              </div>
              <div className="cell-control r-e-text-area">
                <input
                  type="text"
                  value={chosenPlan?.priority}
                  onChange={(e) =>
                    setChosenPlan((p) => {
                      let prev = { ...p };
                      prev["priority"] = e.target.value;
                      return prev;
                    })
                  }
                ></input>
              </div>
            </div>
          </div>
          <div className="created-row">
            <div className="creation-cell" style={{ width: "50%" }}>
              <div className="cell-name">
                <div>Shift</div>
              </div>
              <div className="cell-control r-e-text-area">
                <input
                  type="text"
                  value={
                    chosenPlan?.siteShift?.start +
                    " - " +
                    chosenPlan?.siteShift?.end
                  }
                  disabled
                ></input>
              </div>
            </div>
            <div className="creation-cell" style={{ width: "50%" }}>
              <div className="cell-name">
                <div>Plan Date</div>
              </div>
              <div className="cell-control r-e-text-area">
                <input type="text" value={planDate} disabled></input>
              </div>
            </div>
          </div>
          <div className="created-row"></div>
          <div className="btn-controls">
            <div className="create-btn" onClick={addProdPlan}>
              Add
            </div>
            <div
              className="cancel-btn"
              onClick={() => {
                setChosenPlan((prev) => {
                  let toBe = { ...prev };
                  return {};
                });
                setShowAddDiag(!showAddDiag);
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductionPlan;
