import { useEffect, useState } from "react";
import "./Platform.css";
import { config } from "../config";

function PlantMgmt(props) {
  const [id, setId] = useState(props.plant.id);
  const [name, setName] = useState(props.plant.name);
  const [site, setSite] = useState(
    props.plant.site != undefined ? props.plant.site.id : props.plant.site
  );
  const [sites, setSites] = useState([]);

  useEffect(() => {
    getItems("sites");
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        setSites(actualData);
      });
  }

  function savePlant() {
    let plant = {};
    plant["id"] = id;
    plant["name"] = name;
    plant["site"] = { id: site };
    console.log(plant);
    save(plant);
  }

  function save(plant) {
    fetch(config.apiUrl + "plants/", {
      method: id > 0 ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(plant),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Plant updated!");
        props.goBack();
      });
  }

  return (
    <div className="f-sh-dtl-container">
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Name</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Site</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "4rem" }}
      >
        <div className="create-btn" onClick={savePlant}>
          Save Plant
        </div>
      </div>
    </div>
  );
}

export default PlantMgmt;
