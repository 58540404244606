import { useState } from "react";
import { config } from "../config";
import "./Platform.css";
import Shift from "./Shift";

function SiteMgmt(props) {
  const [id, setId] = useState(props.site.id);
  const [name, setName] = useState(props.site.name);
  const [shifts, setShifts] = useState(props.site.shifts);
  const [updateCount, setUpdateCount] = useState(0);

  console.log(props.site);

  function addShift() {
    setShifts((prev) => {
      let toBeUpdated = [...prev];
      toBeUpdated.push({});
      return toBeUpdated;
    });
  }

  function deleteShift(id) {
    setShifts((prev) => {
      let toBeUpdated = [...prev];
      toBeUpdated.splice(id, 1);
      return toBeUpdated;
    });
    setUpdateCount(updateCount + 1);
  }

  function dataChanged(id, what, value) {
    setShifts((prev) => {
      let toBeUpdated = [...prev];
      let updatedShift = toBeUpdated[id];
      updatedShift[what] = value;
      toBeUpdated.splice(id, 1, updatedShift);
      return toBeUpdated;
    });
  }

  function saveSite() {
    let site = {};
    site["id"] = id;
    site["name"] = name;
    site["shifts"] = shifts.map((shift, inx) => {
      return { ...shift, number: inx + 1 };
    });
    save(site);
  }

  function save(site) {
    fetch(config.apiUrl + "sites/", {
      method: id > 0 ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(site),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Site updated!");
        props.goBack();
      });
  }

  return (
    <div className="f-sh-dtl-container">
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Name</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div
          className="add-shift-btn"
          style={{ width: "100%" }}
          onClick={() => addShift()}
        >
          Add Shift
        </div>
      </div>
      {shifts.map((shift, indx) => {
        return (
          <Shift
            key={indx + "" + updateCount}
            id={indx + 1}
            start={shift.start}
            end={shift.end}
            dataChanged={dataChanged}
            delete={deleteShift}
          ></Shift>
        );
      })}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "4rem" }}
      >
        <div className="create-btn" onClick={saveSite}>
          Save Site
        </div>
      </div>
    </div>
  );
}

export default SiteMgmt;
