import { useEffect, useState, useRef } from "react";
import { config } from "../config";
import { MaterialReactTable } from "material-react-table";
import { Box, Typography, Button } from "@mui/material";
import {
  GetApp,
  Upload,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

function AttendanceMgmt(props) {
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const [site, setSite] = useState();
  const [sites, setSites] = useState([]);
  const [tableData, setTableData] = useState({ header: [], rows: [] });
  const [dt, setDt] = useState();
  const [shift, setShift] = useState();
  var cols = ["Site", "Date", "Shift", "User", "In-Time", "Out-Time"];
  var keys = ["site", "dt", "shift", "user", "inTime", "outTime"];
  const [header, setHeader] = useState(
    cols.map((element, inx) => {
      return {
        accessorKey: keys[inx],
        header: element,
      };
    })
  );

  useEffect(() => {
    getItems("sites");
  }, []);

  function getAttendance() {
    if (site != undefined && site != "") {
      if (shift != undefined && shift != "")
        if (dt != undefined && dt != "") {
          props.raiseAlert("loading", "start");
          let aR = {};
          aR["site"] = site;
          aR["shift"] = shift;
          aR["dt"] = dt;
          fetch(config.apiUrl + "attendance/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("access")).access_token,
            },
            body: JSON.stringify(aR),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
            })
            .then((actualData) => {
              props.raiseAlert("loading", "end");
              props.raiseAlert("green", "Fetched Attendance!");
              setTableData({
                rows: actualData.map((aD) => {
                  let data = { ...aD };
                  data["site"] = sites.filter((s) => s.id == site)[0].name;
                  data["user"] = aD.user.fullName;
                  data["shift"] =
                    "Shift " +
                    sites
                      .filter((s) => s.id == site)[0]
                      .shifts.filter((s) => s.id == shift)[0].number;
                  data["dt"] = aD.dt;
                  data["inTime"] = aD.inTime;
                  data["outTime"] = aD.outTime;
                  return data;
                }),
                header: cols.map((element, inx) => {
                  return {
                    accessorKey: keys[inx],
                    header: element,
                  };
                }),
              });
            });
        } else props.raiseAlert("red", "Please select a Date!");
      else props.raiseAlert("red", "Please select a Shift!");
    } else {
      props.raiseAlert("red", "Please select a Site!");
    }
  }

  function getTemplate() {
    console.log("Getting template");
    const a = document.createElement("a");
    a.href = config.apiUrl + "attendance/template/";
    a.download = (config.apiUrl + "attendance/template/").split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        // props.raiseAlert(
        //   "green",
        //   "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        // );
        if (what === "sites") setSites(actualData);
      });
  }

  function handleFileChange(e) {
    console.log("Handling file change");
    if (e.target.files) {
      const f = new FormData();
      f.append("file", e.target.files[0]);
      fetch(config.apiUrl + "attendance/upload/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        },
        body: f,
      }).then((response) => {
        if (response.ok) {
          getAttendance();
          props.raiseAlert("green", "Data Uploaded");
        }
      });
    }
  }

  return (
    <div className="f-dtl-container">
      <div
        className="created-row"
        style={{ width: "95%", margin: "2rem 0rem" }}
      >
        <div className="creation-cell" style={{ width: "20%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={shift}
              onChange={(e) => setShift(e.target.value)}
            >
              <option value={null}>Select a Shift</option>
              {site != null && sites.length > 0
                ? sites
                    .filter((s) => s.id == site)[0]
                    .shifts.sort((a, b) => a.number - b.number)
                    .map((shift, inx) => {
                      return (
                        <option key={inx} value={shift.id}>
                          {"Shift " + shift.number}
                        </option>
                      );
                    })
                : []}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={dt}
              onChange={(e) => setDt(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "10%" }}>
          <div className="btn create-btn-n" onClick={() => getAttendance()}>
            Filter
          </div>
        </div>
      </div>
      <div className="f-table">
        {
          <MaterialReactTable
            columns={header}
            data={tableData.rows}
            enableStickyHeader
            enableStickyFooter
            renderTopToolbarCustomActions={({ table }) => (
              <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
                <Typography
                  variant="h6"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "1.8rem",
                    alignSelf: "center",
                  }}
                >
                  Attendance
                </Typography>{" "}
                <div
                  //export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={getTemplate}
                  className="create-btn-n"
                >
                  Get Template
                </div>
                <div onClick={handleClick} className="create-btn-b">
                  Upload
                </div>
              </Box>
            )}
            muiTableHeadCellColumnActionsButtonProps={
              config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
            }
            muiTableHeadCellFilterTextFieldProps={
              config.mrtStyle.muiTableHeadCellFilterTextFieldProps
            }
            muiTableContainerProps={config.mrtStyle.muiTableContainerProps}
            initialState={{
              density: "compact",
              columnVisibility: { id: false },
              pagination: {
                pageSize: 20,
                pageIndex: 0,
              },
            }}
            muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
            muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
            muiTableBodyProps={config.mrtStyle.muiTableBodyProps}
            muiBottomToolbarProps={config.mrtStyle.muiBottomToolbarProps}
          ></MaterialReactTable>
        }
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default AttendanceMgmt;
