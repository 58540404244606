import { useState, useEffect } from "react";
import "./Platform.css";
import { config } from "../config";

function EquipmentsMgmt(props) {
  const [name, setName] = useState(props.equipment.name);
  const [id, setId] = useState(props.equipment.id);
  const [make, setMake] = useState(props.equipment.make);
  const [model, setModel] = useState(props.equipment.model);
  const [area, setArea] = useState(
    props.equipment.area != undefined
      ? props.equipment.area.id
      : props.equipment.area
  );
  const [plant, setPlant] = useState(
    props.equipment.area != undefined
      ? props.equipment.area.mfgLine.plant.id
      : props.equipment.plant
  );
  const [site, setSite] = useState(
    props.equipment.area != undefined
      ? props.equipment.area.mfgLine.plant.site.id
      : props.equipment.site
  );
  const [mfgLine, setMfgLine] = useState(
    props.equipment.area != undefined
      ? props.equipment.area.mfgLine.id
      : props.equipment.mfgLine
  );
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgLines, setMfgLines] = useState([]);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    getItems("sites");
    getItems("plants");
    getItems("mfglines");
    getItems("areas");
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") setSites(actualData);
        else if (what === "plants") setPlants(actualData);
        else if (what === "areas") setAreas(actualData);
        else setMfgLines(actualData);
      });
  }

  function saveEquipment() {
    let equipment = {};
    equipment["name"] = name;
    equipment["id"] = id;
    equipment["make"] = make;
    equipment["model"] = model;
    equipment["area"] = { id: area };
    console.log(equipment);
    save(equipment);
  }

  function save(equipment) {
    fetch(config.apiUrl + "equipments/", {
      method: id > 0 ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(equipment),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Mfg. Line updated!");
        props.goBack();
      });
  }

  return (
    <div className="f-sh-dtl-container">
      <div className="created-row">
        <div className="creation-cell" style={{ width: "50%" }}>
          <div className="cell-name">
            <div>ID</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "50%" }}>
          <div className="cell-name">
            <div>Name</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "50%" }}>
          <div className="cell-name">
            <div>Make</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={make}
              onChange={(e) => setMake(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "50%" }}>
          <div className="cell-name">
            <div>Model</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Site</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Plant</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Mfg. Line</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Select a Mfg. Line</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Area</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            >
              <option value={null}>Select an Area</option>
              {areas
                .filter((area) => area.mfgLine.id == mfgLine)
                .map((area, inx) => {
                  return (
                    <option key={inx} value={area.id}>
                      {area.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "4rem" }}
      >
        <div className="create-btn" onClick={saveEquipment}>
          Save Equipment
        </div>
      </div>
    </div>
  );
}

export default EquipmentsMgmt;
