import { useState } from "react";
import { config } from "../config";
import AuditMgmt from "../platform/AuditMgmt";
import DepartmentMgmt from "../platform/DepartmentMgmt";
import "./Insights.css";
import RoleMgmt from "../platform/RoleMgmt";
import SettingsMgmt from "../platform/SettingsMgmt";
import UserMgmt from "../platform/UserMgmt";
import SiteHierarchy from "../platform/SiteHierarchy";
import ManningNorms from "../platform/ManningNorms";
import ProductionPlan from "../platform/ProductionPlan";
import ProductMaster from "../platform/ProductMaster";
import SkillMatrixMgmt from "../platform/SkillMatrixMgmt";
import QualificationMgmt from "../platform/QualificationMgmt";
import AttendanceMgmt from "../platform/AttendanceMgmt";
import AllocationTrend from "./AllocationTrend";
import SkillAnalysis from "./SkillAnalysis";
import PlanAnalysis from "./PlanAnalysis";

function Insights(props) {
  const [itemClicked, setItemClicked] = useState("");

  return (
    <div className="dashboard-container">
      <div className="u-d-container">
        <div className="u-menu p-menu-sidebar">
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("allocation-trend")}
          >
            <div
              className={
                itemClicked === "allocation-trend"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-users-between-lines"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Allocation Trend</div>
          </div>

          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("plan-analysis")}
          >
            <div
              className={
                itemClicked === "plan-analysis"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-clipboard-question"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Planning Analysis</div>
          </div>
          <div
            className={"u-menu-head p-menu "}
            onClick={() => setItemClicked("skill-analysis")}
          >
            <div
              className={
                itemClicked === "skill-analysis"
                  ? "selected-menu-head"
                  : "unselected-menu-head"
              }
            >
              <i className="fa-solid fa-graduation-cap"></i>
            </div>
            <div style={{ fontWeight: "bold" }}>Skills Analysis</div>
          </div>
        </div>
        {itemClicked === "allocation-trend" && (
          <AllocationTrend raiseAlert={props.raiseAlert}></AllocationTrend>
        )}
        {itemClicked === "plan-analysis" && (
          <PlanAnalysis raiseAlert={props.raiseAlert}></PlanAnalysis>
        )}
        {itemClicked === "skill-analysis" && (
          <SkillAnalysis raiseAlert={props.raiseAlert}></SkillAnalysis>
        )}
      </div>
    </div>
  );
}

export default Insights;
