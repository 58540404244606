import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { config } from "./components/config";
import jwt from "jwt-decode";
import IdleTimer from "./components/IdleTimer";
import PlatformSetup from "./components/platform/PlatformSetup";
import Dashboard from "./components/Dashboard";
import Campus from "./components/platform/Campus";
import Employees from "./components/platform/Employees";
import Allocations from "./components/Allocations";
import Insights from "./components/insights/Insights";

function App() {
  const [alert, setAlert] = useState(false);
  const [alertTime, setAlertTime] = useState(500);
  const [alertContent, setAlertContent] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("access") != "undefined" &&
      localStorage.getItem("access") != null
      ? jwt(JSON.parse(localStorage.getItem("access"))["access_token"]).exp *
          1000 >
        Date.now()
        ? true
        : false
      : false
  );
  let navigate = useNavigate();
  let timeId = null;
  useEffect(() => {
    // setInterval(() => {
    //   renewToken();
    // }, 500000);
  }, []);

  function raiseAlert(type, message, time) {
    if (type !== "loading") {
      setAlert(true);
      setAlertContent(message);
      setColor("var(--" + type + ")");
      if (timeId != null) {
        clearTimeout(timeId);
      }
      if (time == undefined || time <= 500) {
        setAlertTime(500);
        timeId = setTimeout(() => {
          setAlert(false);
          setAlertContent("");
        }, 500);
      } else {
        setAlertTime(time);
      }
    } else {
      if (message === "start") setLoading(true);
      else setLoading(false);
    }
  }

  function loginHandler() {
    setLoggedIn(true);
    navigate("/dashboard");
  }

  function logoutHandler() {
    localStorage.clear();
    setLoggedIn(false);
  }

  const handleTimeout = () => {
    logoutHandler();
    raiseAlert("red", "User has been logged out due to inactivity", 5000);
  };

  return (
    <div>
      <div className={loading ? "loading " : "close-flex "}>
        <div className="loading-inner">
          <div className="small-e-line"></div>
        </div>
      </div>
      <div
        className={
          "notification " +
          (alert || alertTime > 500 ? "" : " notification-hidden")
        }
      >
        <div style={{ backgroundColor: color }} className="notif-icon">
          {color === "var(--green)" && (
            <i className="fa-solid fa-circle-check"></i>
          )}
          {color === "var(--red)" && (
            <i className="fa-solid fa-circle-exclamation"></i>
          )}
        </div>
        <div style={{ backgroundColor: color }} className="not-msg">
          <div>{alertContent}</div>
        </div>
        {alertTime > 500 && (
          <div
            style={{ color: color, borderColor: color }}
            className="ok-btn"
            onClick={() => {
              setAlert(false);
              setAlertContent("");
              setAlertTime(500);
            }}
          >
            Ok
          </div>
        )}
      </div>
      <Navbar
        raiseAlert={raiseAlert}
        isLoggedIn={loggedIn}
        onLogout={logoutHandler}
      ></Navbar>
      {loggedIn && (
        <div className={loading ? "inactive " : ""}>
          <div>
            <IdleTimer onTimeout={handleTimeout} />
            {JSON.parse(localStorage.getItem("user")).role.filter(
              (role) =>
                ["ROLE_SYSTEM_ADMIN", "ROLE_QA", "ROLE_ADMIN"].includes(role) ||
                1 == 1
            ).length > 0 && (
              <div>
                <Routes>
                  <Route
                    path="/platform"
                    element={
                      <PlatformSetup raiseAlert={raiseAlert}></PlatformSetup>
                    }
                  ></Route>
                  <Route
                    path="/campus"
                    element={<Campus raiseAlert={raiseAlert}></Campus>}
                  ></Route>
                  <Route
                    path="/employees"
                    element={<Employees raiseAlert={raiseAlert}></Employees>}
                  ></Route>
                  <Route
                    path="/dashboard"
                    element={<Dashboard raiseAlert={raiseAlert}></Dashboard>}
                  ></Route>
                  <Route
                    path="/insights"
                    element={<Insights raiseAlert={raiseAlert}></Insights>}
                  ></Route>
                </Routes>
              </div>
            )}
          </div>
        </div>
      )}
      {!loggedIn && (
        <Routes>
          <Route
            path="/allocations"
            element={<Allocations raiseAlert={raiseAlert}></Allocations>}
          ></Route>
          <Route
            path="/*"
            element={
              <Login raiseAlert={raiseAlert} onLogin={loginHandler}></Login>
            }
          ></Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
