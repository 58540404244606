import { useEffect, useState } from "react";
import { config } from "../config";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

Chart.register({
  id: "custom_canvas_background_color",
  beforeDraw: (chart) => {
    if (
      chart.config.options.chartArea &&
      chart.config.options.chartArea.backgroundColor
    ) {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  },
});

function AllocationTrend(props) {
  const [areas, setAreas] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState([]);
  const [mfgLines, setMfgLines] = useState([]);
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgLine, setMfgLine] = useState();
  const [site, setSite] = useState();
  const [plant, setPlant] = useState();
  const [shift, setShift] = useState();

  useEffect(() => {
    getItems("sites");
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") {
          setSites(actualData);
          getItems("plants");
        } else if (what === "plants") {
          setPlants(actualData);
          getItems("mfglines");
        } else {
          setMfgLines(actualData);
          getMfgLine(JSON.parse(localStorage.getItem("user"))["department"]);
        }
      });
  }

  function getMfgLine(mfgLine) {
    fetch(config.apiUrl + "mfglines/" + mfgLine + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched " + mfgLine + "!");
        setSite(actualData["plant"]["site"]["id"]);
        setPlant(actualData["plant"]["id"]);
        setMfgLine(actualData["id"]);
        getAreas(actualData["id"]);
      })
      .catch((err) => {
        setMfgLine(null);
      });
  }

  function getAreas(mL) {
    fetch(config.apiUrl + "areas/mfgline/" + mL + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Areas!");
        setAreas(actualData);
        // getProductionPlans(mL, actualData);
      });
  }

  function filterClicked() {
    if (site > 0) {
      if (plant > 0) {
        if (mfgLine > 0) {
          getAreas(mfgLine);
        } else {
          props.raiseAlert("red", "Please select a Manufacturing Line!");
        }
      } else {
        props.raiseAlert("red", "Please select a Plant!");
      }
    } else {
      props.raiseAlert("red", "Please select a Site!");
    }
  }

  return (
    <div className="f-dtl-container">
      <div
        className="created-row"
        style={{ width: "95%", margin: "2rem 0rem" }}
      >
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={site}
              onChange={(e) => {
                console.log(e.target);
                setSite(e.target.value);
              }}
            >
              <option value={-1}>Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Mfg. Line</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "14%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "14%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "8%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select value={shift} onChange={(e) => setShift(e.target.value)}>
              <option value={null}>Shift</option>
              {site != -1 &&
                site != null &&
                sites
                  .filter((s) => {
                    return s.id == site;
                  })[0]
                  .shifts.map((sft, inx) => {
                    return (
                      <option key={inx} value={sft.id}>
                        {"Shift " + sft.number}
                      </option>
                    );
                  })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ flexGrow: "1" }}>
          <div className="btn create-btn-n" onClick={filterClicked}>
            Filter
          </div>
        </div>
      </div>
      <div className="f-in-table">
        <div className="stat-line" style={{ gap: "1rem", height: "20vh" }}>
          <div
            className="stat"
            style={{ backgroundColor: config.borderColors[0], color: "white" }}
          >
            <div className="st-hd">Resources Required</div>
            <div className="st-val">542</div>
          </div>
          <div
            className="stat"
            style={{ backgroundColor: config.borderColors[1], color: "white" }}
          >
            <div className="st-hd">Attendance</div>
            <div className="st-val">540</div>
          </div>
          <div
            className="stat"
            style={{ backgroundColor: config.borderColors[2], color: "white" }}
          >
            <div className="st-hd">Allocation</div>
            <div className="st-val">535</div>
          </div>
          <div
            className="stat"
            style={{ backgroundColor: config.borderColors[3], color: "white" }}
          >
            <div className="st-hd">Automated Allocation</div>
            <div className="st-val">525</div>
          </div>
          <div
            className="stat"
            style={{ backgroundColor: config.borderColors[4], color: "white" }}
          >
            <div className="st-hd">Manual Allocation</div>
            <div className="st-val">10</div>
          </div>
        </div>
        <div className="ch-line">
          <div className="ch-hd" style={{ height: "10%" }}>
            <div className="grow"></div>
            <div className="ch-selection">
              <select type="text">
                <option value="Automated v Manual">Automated v Manual</option>
                <option value="Automated v Manual">
                  Requirement v Availability
                </option>
              </select>
            </div>
          </div>
          <div className="ch-ch">
            <Line
              options={config.lOptions}
              data={{
                labels: ["A", "B", "C"],
                datasets: [
                  {
                    label: "Dataset 1",
                    data: [1, 2, 3],
                    backgroundColor: config.borderColors[3] + "ee",
                    borderColor: config.borderColors[3],
                    cubicInterpolationMode: "monotone",
                    tension: 0.4,
                  },
                  {
                    label: "Dataset 2",
                    data: [3, 1, 1],
                    backgroundColor: config.borderColors[4] + "ee",
                    borderColor: config.borderColors[4],
                    cubicInterpolationMode: "monotone",
                    tension: 0.4,
                  },
                ],
              }}
            ></Line>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllocationTrend;
