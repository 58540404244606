import { config } from "./config";
import "./Dashboard.css";
import { useEffect, useMemo, useState, useRef } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Typography, Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";

function Allocations(props) {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("id"));
  const [tableData, setTableData] = useState({ header: [], rows: [] });
  const [shift, setShift] = useState();
  const [mfgLines, setMfgLines] = useState([]);
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgLine, setMfgLine] = useState();
  const [site, setSite] = useState();
  const [plant, setPlant] = useState();
  const [planDate, setPlanDate] = useState();
  const [productionPlans, setProductionPlans] = useState([]);
  var cols = [
    "Employee Code",
    "Employee",
    "Area Code",
    "Area Name",
    "Product/Operation",
    "Batch Number",
    "Priority",
  ];
  var keys = [
    "employeeCode",
    "fullName",
    "areaCode",
    "areaName",
    "operation",
    "batchNumber",
    "priority",
  ];

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    getItems("sites");
    getItems("plants");
    getItems("mfglines");
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + "open/" + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") setSites(actualData);
        else if (what === "plants") setPlants(actualData);
        else setMfgLines(actualData);
      });
  }

  function getProductionPlans(mfgLine) {
    if (site != null) {
      if (plant != null) {
        if (mfgLine != null) {
          if (shift != null) {
            if (planDate != null) {
              console.log(planDate);
              let req = {};
              req["dt"] = planDate;
              req["shift"] = shift;
              req["mfgLineId"] = mfgLine;
              fetch(config.apiUrl + "open/mfgline_shift_date/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
                body: JSON.stringify(req),
              })
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                })
                .then((actualData) => {
                  props.raiseAlert("green", "Fetched Production Plans!");
                  setProductionPlans(actualData);
                  console.log(actualData);
                  setTableData({
                    rows: actualData
                      .map((aD) => {
                        let data = [];
                        let userData = {};
                        userData["areaCode"] = aD.area.code;
                        userData["areaName"] = aD.area.name;
                        userData["operation"] = aD.operation;
                        userData["batchNumber"] = aD.batchNumber;
                        userData["priority"] = aD.priority;

                        aD.allocationPlans.forEach((a) => {
                          var d = { ...userData };
                          d["fullName"] = a.user.fullName;
                          d["employeeCode"] = a.user.employeeCode;
                          data.push(d);
                        });
                        return data;
                      })
                      .flatMap((f) => f)
                      .sort((a, b) => a.fullName.localeCompare(b.fullName)),
                    header: cols.map((element, inx) => {
                      return {
                        accessorKey: keys[inx],
                        header: element,
                      };
                    }),
                  });
                });
            } else {
              props.raiseAlert("red", "Please select plan date!");
            }
          } else {
            props.raiseAlert("red", "Please select a Shift!");
          }
        } else {
          props.raiseAlert("red", "Please select a Manufacturing Line!");
        }
      } else {
        props.raiseAlert("red", "Please select a Plant!");
      }
    } else {
      props.raiseAlert("red", "Please select a Site!");
    }
  }

  return (
    <div className="dashboard-container">
      <div
        className={"created-row "}
        style={{ width: "95%", margin: "2rem 0rem 0rem 0rem" }}
      >
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Select a Mfg. Line</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>{" "}
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={shift}
              onChange={(e) => setShift(e.target.value)}
            >
              <option value={null}>Select a Shift</option>
              {site != null && sites.length > 0
                ? sites
                    .filter((s) => s.id == site)[0]
                    .shifts.sort((a, b) => a.number - b.number)
                    .map((shift, inx) => {
                      return (
                        <option key={inx} value={shift.id}>
                          {"Shift " + shift.number}
                        </option>
                      );
                    })
                : []}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={planDate}
              onChange={(e) => setPlanDate(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "10%" }}>
          <div
            className="btn create-btn-n"
            onClick={() => getProductionPlans(mfgLine)}
          >
            Filter
          </div>
        </div>
      </div>
      {tableData.rows.length > 0 && (
        <div className="f-table">
          {
            <MaterialReactTable
              columns={tableData.header}
              data={tableData.rows}
              enableStickyHeader
              enableStickyFooter
              renderTopToolbarCustomActions={({ table }) => (
                <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
                  <Typography
                    variant="h6"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontFamily: "Poppins",
                      fontSize: "1.8rem",
                      alignSelf: "center",
                    }}
                  >
                    Allocation
                  </Typography>{" "}
                </Box>
              )}
              muiTableHeadCellColumnActionsButtonProps={
                config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
              }
              muiTableHeadCellFilterTextFieldProps={
                config.mrtStyle.muiTableHeadCellFilterTextFieldProps
              }
              muiTableContainerProps={
                config.mrtStyle.muiFullTableContainerProps
              }
              initialState={{
                density: "compact",
                columnVisibility: { id: false },
                pagination: {
                  pageSize: 1000,
                  pageIndex: 0,
                },
              }}
              muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
              muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
              muiTableBodyProps={config.mrtStyle.muiTableBodyProps}
              muiBottomToolbarProps={config.mrtStyle.muiBottomToolbarProps}
            ></MaterialReactTable>
          }
        </div>
      )}
    </div>
  );
}

export default Allocations;
