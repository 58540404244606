import { useEffect, useState } from "react";
import { config } from "../config";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Typography } from "@mui/material";
import AuditStrip from "./AuditStrip";
import Multiselect from "multiselect-react-dropdown";

function AuditMgmt(props) {
  const [tableData, setTableData] = useState({ header: [], rows: [] });
  const [auditLogs, setAuditLogs] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState("");
  const [filteredType, setFilteredType] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  var cols = [
    "ID",
    "UserName",
    "Module",
    "Action",
    "PrevState",
    "NewState",
    "Activity Timestamp",
  ];
  var keys = [
    "id",
    "userName",
    "type",
    "action",
    "prevState",
    "newState",
    "auditDt",
  ];

  useEffect(() => {
    getAuditLogs();
    getUsers();
  }, [pagination.pageIndex]);

  function updatePagination(index, pageSize) {
    setPagination((prev) => {
      return {
        pageIndex: index,
        pageSize: 20,
      };
    });
  }

  function getUsers() {
    fetch(config.apiUrl + "users/basic/dtl/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        // console.log(actualData);
        setUsers(actualData);
      });
  }

  function getAuditLogs() {
    fetch(
      config.apiUrl +
        "audit/?pageSize=" +
        pagination.pageSize +
        "&pageNumber=" +
        pagination.pageIndex +
        "&user=" +
        filteredUser +
        "&type=" +
        filteredType +
        "&startDate=" +
        start +
        "&endDate=" +
        end,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Audit Logs!");
        setAuditLogs((prev) => {
          let toBeUpdated = [...prev];
          toBeUpdated.concat(actualData.auditTrails);
          return toBeUpdated;
        });
        setTotalPages(actualData.totalPages);
        setTableData((prev) => {
          let toBeUpdated = { ...prev };
          let rows = actualData.auditTrails.map((aD) => {
            let data = { ...aD };
            return data;
          });
          let header = cols.map((element, inx) => {
            return {
              accessorKey: keys[inx],
              header: element,
            };
          });
          toBeUpdated.rows = rows;
          toBeUpdated.header = header;
          return toBeUpdated;
        });
        //   setTableData((prev) => {
        //     let toBeUpdated = { ...prev };
        //     toBeUpdated.rows.push(
        //       actualData.auditTrails.map((aD) => {
        //         let data = { ...aD };
        //         return data;
        //       })
        //     );
        //     toBeUpdated.header = cols.map((element, inx) => {
        //       return {
        //         accessorKey: keys[inx],
        //         header: element,
        //       };
        //     });
        //     return toBeUpdated;
        //   });
      });
  }

  function pageNumbers(totalPages, currentPage, maxPagesToShow = 5) {
    const pages = [];

    if (totalPages <= 1) return pages;

    const half = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - half);
    let endPage = Math.min(totalPages, currentPage + half);

    if (currentPage <= half) {
      endPage = Math.min(totalPages, maxPagesToShow);
    }

    if (currentPage + half >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  return (
    <div className="f-dtl-container">
      <div className="f-audit-table">
        {/* <MaterialReactTable
          columns={tableData.header}
          data={tableData.rows}
          enableStickyHeader
          enableStickyFooter
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
              <Typography
                variant="h6"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "Poppins",
                  fontSize: "1.8rem",
                  alignSelf: "center",
                }}
              >
                Audit Trail
              </Typography>
            </Box>
          )}
          muiTableContainerProps={config.mrtStyle.muiTableContainerProps}
          initialState={{
            density: "compact",
            columnVisibility: { id: false },
          }}
          muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
          muiTableHeadCellColumnActionsButtonProps={
            config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
          }
          muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
          muiTableHeadCellFilterTextFieldProps={
            config.mrtStyle.muiTableHeadCellFilterTextFieldProps
          }
          onPaginationChange={setPagination}
          state={{ pagination }}
          rowCount={pagination.pageSize * totalPages}
          manualPagination={true}
          // pageIndex={pagination.pageIndex}
          // pageIndex={}
          pageCount={totalPages}
          muiTableBodyProps={config.mrtStyle.muiTableBodyProps}
          // autoResetPageIndex={false}
        ></MaterialReactTable> */}

        <div className="f-p-dtl-head">
          <div className="f-dtl-name">Audit Trail</div>
        </div>
        <div
          className={"created-row"}
          style={{ width: "95%", margin: "2rem 0rem 2rem 0rem" }}
        >
          <div className="creation-cell" style={{ width: "22.5%" }}>
            <div className="cell-control" style={{ width: "100%" }}>
              <Multiselect
                style={
                  filteredUser === ""
                    ? config.multiSelectStyle
                    : config.platformMultiSelectStyle
                }
                onSelect={(e) => {
                  setFilteredUser(e[0]["username"]);
                }}
                onRemove={(e) => setFilteredUser("")}
                selectedValues={users.filter(
                  (user) => user["username"] === filteredUser
                )}
                options={users}
                displayValue={"fullName"}
                selectionLimit={1}
                hidePlaceholder
              ></Multiselect>
            </div>
          </div>
          <div className="creation-cell" style={{ width: "22.5%" }}>
            <div className="cell-control" style={{ width: "100%" }}>
              <select
                type="text"
                onChange={(e) => setFilteredType(e.target.value)}
              >
                <option value={null}>Type</option>
                {["ROLE", "DEPARTMENT", "USER", "USER_ROLE"].map(
                  (plant, inx) => {
                    return (
                      <option key={inx} value={plant}>
                        {plant}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
          <div className="creation-cell" style={{ width: "22.5%" }}>
            <div className="cell-control" style={{ width: "100%" }}>
              <input
                type="date"
                onChange={(e) => {
                  console.log(e.target.value);
                  setStart(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="creation-cell" style={{ width: "22.5%" }}>
            <div className="cell-control" style={{ width: "100%" }}>
              <input
                type="date"
                onChange={(e) => {
                  setEnd(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="creation-cell" style={{ width: "10%" }}>
            <div className="btn create-btn-n" onClick={getAuditLogs}>
              Filter
            </div>
          </div>
        </div>
        <div className="audit-scroll-container">
          <div className="audit-container">
            {tableData.rows.map((r, inx) => {
              return <AuditStrip data={r}></AuditStrip>;
            })}
          </div>
        </div>
        <div className="pagination-ctrl">
          <div className="pages">
            {pageNumbers(totalPages, pagination.pageIndex + 1).map((x) => (
              <div
                className={
                  pagination.pageIndex + 1 == x ? "selected-page" : "page"
                }
                onClick={() => updatePagination(x - 1, 20)}
              >
                {x}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditMgmt;
