import "./AuditTrip.css";

function AuditStrip(props) {
  const loggingCues = ["logged In", "logged Out", "login attempt"];
  const isLogInAudit =
    loggingCues.filter(
      (l) => props.data.newState != null && props.data.newState.endsWith(l)
    ).length > 0;
  const type = props.data.type;
  const action = props.data.action?.toLowerCase();
  const icon = isLogInAudit
    ? "fa-arrow-right-to-bracket"
    : type === "USER"
    ? "fa-user-large"
    : type === "USER_ROLE"
    ? "fa-user-gear"
    : type === "DEPARTMENT"
    ? "fa-building-columns"
    : type === "ROLE"
    ? "fa-fingerprint"
    : "fa-gear";
  const actMsg =
    type === "USER_ROLE" && action === "created" ? "attached" : action;
  const bgColor = isLogInAudit
    ? "var(--dark)"
    : type === "USER"
    ? "var(--accent)"
    : type === "USER_ROLE"
    ? "orange"
    : type === "DEPARTMENT"
    ? "var(--green)"
    : type === "ROLE"
    ? "dodgerblue"
    : "var(--red)";
  const msg =
    props.data.userName +
    " " +
    actMsg +
    " " +
    type?.toLowerCase() +
    " " +
    (action === "created"
      ? props.data.newState == null
        ? ""
        : props.data.newState
      : "") +
    " | ID: " +
    props.data.pkValue;

  return (
    <div className="audit-strip">
      <div className="a-icon" style={{ backgroundColor: bgColor }}>
        <i className={"fa-solid " + icon}></i>
      </div>
      <div>
        {loggingCues.filter(
          (l) => props.data.newState != null && props.data.newState.endsWith(l)
        ).length > 0
          ? props.data.newState
          : msg}
      </div>
      <div className="a-adt-dt">{props.data.auditDt}</div>
    </div>
  );
}

export default AuditStrip;
