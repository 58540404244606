import { useEffect, useState } from "react";
import SiteMgmt from "./SiteMgmt";
import PlantMgmt from "./PlantMgmt";
import MfgLinesMgmt from "./MfgLinesMgmt";
import AreasMgmt from "./AreasMgmt";
import EquipmentsMgmt from "./EquipmentsMgmt";
import { config } from "../config";

function SiteHierarchyMgmt(props) {
  const [addScreen, setAddScreen] = useState(false);
  const [item, setItem] = useState();
  const [itemClicked, setItemClicked] = useState(false);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const icons = {
    Site: " fa-industry",
    Plants: " fa-warehouse",
    "Manufacturing Lines": " fa-xmarks-lines",
    Areas: " fa-map-location-dot",
    Equipments: " fa-screwdriver-wrench",
  };

  useEffect(() => {
    if (props.type === "Site") getItems("sites");
    else if (props.type === "Plants") getItems("plants");
    else if (props.type === "Manufacturing Lines") getItems("mfglines");
    else if (props.type === "Areas") getItems("areas");
    else if (props.type === "Equipments") getItems("equipments");
  }, [props.type]);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        setItems(actualData);
      });
  }

  function handleSiteClick(site) {
    setItemClicked(true);
    setAddScreen(true);
    setItem(site);
  }

  function goBack() {
    setAddScreen(false);
    setItemClicked(false);
  }

  return (
    <div className="f-sh-container">
      <div className="f-sh-head">
        {!addScreen && (
          <div className="search-bar">
            <div className="search-control">
              <input
                type="text"
                placeholder={props.type}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            </div>
            <div className="search-icon">
              <i
                className="fa-solid fa-search"
                style={{ fontSize: "2.4rem" }}
              ></i>
            </div>
          </div>
        )}
        <div
          className="create-btn-n"
          onClick={() => {
            setAddScreen(!addScreen);
            setItemClicked(false);
          }}
        >
          {addScreen ? "Go Back" : "Add " + props.type}
        </div>
      </div>
      {!addScreen && (
        <div className="f-sh-dtl-container">
          {items
            .filter(
              (site) =>
                site.name.toLowerCase().includes(search.toLowerCase()) ||
                search === ""
            )
            .map((site, indx) => {
              return (
                <div
                  className="site-card"
                  onClick={() => handleSiteClick(site)}
                >
                  <div className="site-icon">
                    <i className={"fa-solid" + icons[props.type]}></i>
                  </div>
                  <div key={indx} className="site-name">
                    {site.name}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {addScreen && props.type === "Site" && (
        <SiteMgmt
          site={itemClicked ? item : { name: "", shifts: [] }}
          raiseAlert={props.raiseAlert}
          goBack={goBack}
        ></SiteMgmt>
      )}
      {addScreen && props.type === "Plants" && (
        <PlantMgmt
          plant={itemClicked ? item : { name: "", site: "" }}
          raiseAlert={props.raiseAlert}
          goBack={goBack}
        ></PlantMgmt>
      )}
      {addScreen && props.type === "Manufacturing Lines" && (
        <MfgLinesMgmt
          mfgLine={itemClicked ? item : { name: "" }}
          goBack={goBack}
          raiseAlert={props.raiseAlert}
        ></MfgLinesMgmt>
      )}
      {addScreen && props.type === "Areas" && (
        <AreasMgmt
          goBack={goBack}
          area={
            itemClicked
              ? item
              : {
                  name: "",
                  code: "",
                  type: "",
                }
          }
          raiseAlert={props.raiseAlert}
        ></AreasMgmt>
      )}
      {addScreen && props.type === "Equipments" && (
        <EquipmentsMgmt
          sites={items}
          plants={items}
          mfgLines={items}
          areas={items}
          equipment={
            itemClicked
              ? item
              : {
                  name: "",
                  id: "",
                  make: "",
                  model: "",
                }
          }
          goBack={goBack}
          raiseAlert={props.raiseAlert}
        ></EquipmentsMgmt>
      )}
    </div>
  );
}

export default SiteHierarchyMgmt;
