import { useEffect, useState } from "react";
import "./Platform.css";
import { config } from "./../config";

function MfgLinesMgmt(props) {
  console.log(props);
  const [id, setId] = useState(props.mfgLine.id);
  const [name, setName] = useState(props.mfgLine.name);
  const [plant, setPlant] = useState(
    props.mfgLine.plant != undefined
      ? props.mfgLine.plant.id
      : props.mfgLine.plant
  );
  const [site, setSite] = useState(
    props.mfgLine.plant != undefined
      ? props.mfgLine.plant.site.id
      : props.mfgLine.plant
  );
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);

  useEffect(() => {
    getItems("sites");
    getItems("plants");
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") setSites(actualData);
        else setPlants(actualData);
      });
  }

  function saveMfgLine() {
    let mfgLine = {};
    mfgLine["id"] = id;
    mfgLine["name"] = name;
    mfgLine["plant"] = { id: plant };
    console.log(mfgLine);
    save(mfgLine);
  }

  function save(mfgLine) {
    fetch(config.apiUrl + "mfglines/", {
      method: id > 0 ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(mfgLine),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Mfg. Line updated!");
        props.goBack();
      });
  }

  return (
    <div className="f-sh-dtl-container">
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Name</div>
          </div>
          <div className="cell-control">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Site</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="created-row">
        <div className="creation-cell" style={{ width: "100%" }}>
          <div className="cell-name">
            <div>Plant</div>
          </div>
          <div className="cell-control">
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "4rem" }}
      >
        <div className="create-btn" onClick={saveMfgLine}>
          Save Manufacturing Line
        </div>
      </div>
    </div>
  );
}

export default MfgLinesMgmt;
