import { config } from "./config";
import "./Dashboard.css";
import { useEffect, useMemo, useState, useRef } from "react";

function Dashboard(props) {
  const [shift, setShift] = useState();
  const [data, setData] = useState([]);
  const [mfgLines, setMfgLines] = useState([]);
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgLine, setMfgLine] = useState();
  const [site, setSite] = useState();
  const [plant, setPlant] = useState();
  const [planDate, setPlanDate] = useState();
  const [productionPlans, setProductionPlans] = useState([]);
  const [manningNorms, setManningNorms] = useState([]);
  const [openAll, setOpenAll] = useState(true);
  const [isOpen, setIsOpen] = useState([]);
  const [showDiag, setShowDiag] = useState(false);
  const [showAddDiag, setShowAddDiag] = useState(false);
  const [showRun, setShowRun] = useState(false);
  const [clickedPlan, setClickedPlan] = useState({ user: {} });
  const [clickedPPlan, setClickedPPlan] = useState({ assignment: [] });
  const [availableWorkers, setAvailableWorkers] = useState([]);
  const [search, setSearch] = useState("");
  const [chosenUser, setChosenUser] = useState({});

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    getItems("sites");
    getItems("plants");
    getItems("mfglines");
    getMfgLine(JSON.parse(localStorage.getItem("user"))["department"]);
  }, []);

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") setSites(actualData);
        else if (what === "plants") setPlants(actualData);
        else setMfgLines(actualData);
      });
  }

  function getMfgLine(mfgLine) {
    fetch(config.apiUrl + "mfglines/" + mfgLine + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched " + mfgLine + "!");
        setSite(actualData["plant"]["site"]["id"]);
        setPlant(actualData["plant"]["id"]);
        setMfgLine(actualData["id"]);
      })
      .catch((err) => {
        setMfgLine(null);
        console.log(err);
      });
  }

  function getProductionPlans(mfgLine) {
    if (site != null) {
      if (plant != null) {
        if (mfgLine != null) {
          if (shift != null) {
            if (planDate != null) {
              let req = {};
              req["dt"] = planDate;
              req["shift"] = shift;
              req["mfgLineId"] = mfgLine;
              fetch(config.apiUrl + "production-plans/mfgline_shift_date/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("access")).access_token,
                },
                body: JSON.stringify(req),
              })
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                })
                .then((actualData) => {
                  props.raiseAlert("green", "Fetched Production Plans!");
                  setProductionPlans(actualData);
                  getManningNorms(actualData);

                  setShowRun(
                    actualData.filter((aD) => aD.allocationPlans.length > 0)
                      .length == 0
                  );
                });
            } else {
              props.raiseAlert("red", "Please select plan date!");
            }
          } else {
            props.raiseAlert("red", "Please select a Shift!");
          }
        } else {
          props.raiseAlert("red", "Please select a Manufacturing Line!");
        }
      } else {
        props.raiseAlert("red", "Please select a Plant!");
      }
    } else {
      props.raiseAlert("red", "Please select a Site!");
    }
  }

  function getManningNorms(plans) {
    let areas = plans
      .map((d) => d.area.id)
      .filter((v, i, arr) => arr.indexOf(v) === i);
    fetch(config.apiUrl + "manning-norms/areas/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(areas),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        // props.raiseAlert("green", "Fetched Manning!");
        setIsOpen((prev) => {
          return plans.map((p) => false);
        });
        setData(
          plans
            .filter((d) => d.siteShift.id == shift && d.planDate === planDate)
            .map((d) => {
              let records = [];
              actualData
                .filter((a) => a.area.id == d.area.id && a.work === d.operation)
                .forEach((a) => {
                  records.push({
                    id: d.id,
                    date: d.planDate,
                    areaCode: d.area.code,
                    areaName: d.area.name,
                    shift: "Shift " + d.siteShift.number,
                    pCode: d.operation,
                    batch: d.batchNumber,
                    priority: d.priority,
                    category: a.category,
                    skill: a.skill,
                    count: a.count,
                    assignment: d.allocationPlans.filter(
                      (ab) =>
                        ab.category === a.category &&
                        ab.requiredSkill === a.skill
                    ),
                  });
                });
              return records;
            })
            .flat()
        );
      });
  }

  function deallocate(plan, user) {}

  function updateAllocation(plan, pPlanId) {
    fetch(config.apiUrl + "allocation-plan/" + pPlanId + "/", {
      method: plan["user"] == null ? "DELETE" : "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(plan),
    })
      .then((response) => {
        if (response.ok) {
          return 1;
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Allocation updated!");
        getProductionPlans(mfgLine);
        setShowDiag(false);
        setShowAddDiag(false);
        setClickedPlan({ user: {} });
        setClickedPPlan({ assignment: [] });
      });
  }

  function getAvailableWorkforce(plan) {
    console.log(plan);
    fetch(config.apiUrl + "users/available/" + plan["id"] + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Available Users!");
        setAvailableWorkers(actualData);
      });
    // setShowAddDiag(false);
    // setClickedPPlan({ assignment: [] });
  }

  function runAllocation() {
    let req = {};
    req["dt"] = planDate;
    req["shift"] = shift;
    req["mfgLineId"] = mfgLine;
    fetch(config.apiUrl + "allocation-plan/run/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(req),
    }).then((response) => {
      if (response.ok) {
        getProductionPlans(mfgLine);
      }
    });
  }

  function updateIsOpen(inx) {
    setIsOpen((prev) => {
      let updated = [...prev];
      updated.splice(inx, 1, !prev[inx]);
      console.log(updated);
      return updated;
    });
  }
  return (
    <div className="dashboard-container">
      <div
        className={"created-row " + (showDiag ? "disable" : "")}
        style={{ width: "95%", margin: "2rem 0rem 0rem 0rem" }}
      >
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Select a Mfg. Line</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>{" "}
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={shift}
              onChange={(e) => setShift(e.target.value)}
            >
              <option value={null}>Select a Shift</option>
              {site != null && sites.length > 0
                ? sites
                    .filter((s) => s.id == site)[0]
                    .shifts.sort((a, b) => a.number - b.number)
                    .map((shift, inx) => {
                      return (
                        <option key={inx} value={shift.id}>
                          {"Shift " + shift.number}
                        </option>
                      );
                    })
                : []}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "18%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <input
              type="date"
              value={planDate}
              onChange={(e) => setPlanDate(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "10%" }}>
          <div
            className="btn create-btn-n"
            onClick={() => getProductionPlans(mfgLine)}
          >
            Filter
          </div>
        </div>
      </div>
      {data.length > 0 && (
        <div className={"d-table " + (showDiag ? "disable" : "")}>
          <div className="d-header">
            <div className="d-head">
              Allocation{" "}
              {showRun && (
                <div className="run-icon" onClick={runAllocation}>
                  Run
                  <i className="fa-solid fa-circle-play"></i>
                </div>
              )}
            </div>
            <div
              className="open-btn"
              onClick={() => {
                setOpenAll(!openAll);
              }}
            >
              <i
                className={
                  "fa-solid " +
                  (openAll ? "fa-square-caret-down" : "fa-square-caret-up")
                }
              ></i>
            </div>
          </div>
          <div className="d-data">
            {data
              .map((d) => d.areaCode)
              .filter((v, i, arr) => arr.indexOf(v) === i)
              .map((areaCode, idx) => {
                return (
                  <div>
                    <div className="d-area">
                      <div className="d-10">{areaCode}</div>
                      <div className="d-20">
                        {
                          data.filter((da) => da.areaCode === areaCode)[0]
                            .areaName
                        }
                      </div>
                      {
                        data
                          .filter((d) => d.areaCode === areaCode)
                          .map((d) => {
                            return (
                              <>
                                <div
                                  className="d-30 product"
                                  style={{ textAlign: "center" }}
                                >
                                  {d.pCode}
                                </div>
                                <div className="batch">{d.batch}</div>
                              </>
                            );
                          })[0]
                      }
                      <div>
                        {/* <i onClick={() => updateIsOpen(idx)}
                          className={
                            "fa-solid " +
                            (isOpen[idx] || openAll
                              ? "fa-square-caret-down"
                              : "fa-square-caret-up")
                          }
                        ></i> */}
                      </div>
                    </div>
                    <div className="d-area-data">
                      {data
                        .filter((d) => d.areaCode === areaCode)
                        .map((d, inx) => {
                          return (
                            <div
                              className={
                                "d-line " +
                                (inx % 2 == 0 ? "even " : "odd ") +
                                (openAll || isOpen[idx] ? "" : " close-flex")
                              }
                            >
                              <div className="d-13">{d.category}</div>
                              <div className="d-13">{d.skill}</div>
                              <div className="d-13">{d.count}</div>
                              <div className="d-60">
                                {d.assignment
                                  .sort((a, b) => a.id - b.id)
                                  .map((a, i) => {
                                    return (
                                      <div className="d-user">
                                        <div
                                          className={
                                            i >= d.count
                                              ? "d-user-name-red"
                                              : a.allocation === "DYNAMIC"
                                              ? "d-user-name"
                                              : "d-user-name-yellow"
                                          }
                                        >
                                          {a.user ? a.user.fullName : ""}
                                        </div>
                                        <div
                                          className="d-user-btn"
                                          onClick={() => {
                                            setClickedPlan(a);
                                            setShowDiag(true);
                                          }}
                                        >
                                          <i className="fa-solid fa-close"></i>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="d-5">
                                <div
                                  className={
                                    d.count >
                                    d.assignment.filter(
                                      (b) =>
                                        b.user != null && b.user != undefined
                                    ).length >
                                    0
                                      ? "add-btn"
                                      : "bad-add-btn"
                                  }
                                  onClick={() => {
                                    setClickedPPlan(d);
                                    getAvailableWorkforce(d);
                                    setShowAddDiag(true);
                                  }}
                                >
                                  <i className="fa-solid fa-square-plus"></i>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className={"esign-modal " + (showDiag ? " " : " close-flex")}>
        <div className="create-job-header">
          <div className="flex-row-title margin-btm">
            <i className="fa-solid fa-triangle-exclamation new-job-icon"></i>
            <div className="new-job-head">
              Do you want to de-allocate {clickedPlan.user.fullName} ?
            </div>
          </div>
          <div className="flex-row-title">
            <div
              className="btn-save"
              onClick={() => {
                clickedPlan["allocation"] = "MANUAL";
                clickedPlan["user"] = null;
                updateAllocation(clickedPlan, -1);
              }}
            >
              Yes
            </div>
            <div className="btn-cancel" onClick={() => setShowDiag(false)}>
              Cancel
            </div>
          </div>
        </div>
      </div>
      <div className={"allocate-modal " + (showAddDiag ? " " : " close-flex")}>
        <div className="allocate-header">
          <div className="flex-row-title margin-btm">
            <input
              style={{ outline: ".1rem solid dodgerblue", width: "90%" }}
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
          <div className="avail-users-container">
            {availableWorkers
              .filter((a) =>
                a.fullName.toLowerCase().includes(search.toLowerCase())
              )
              .map((w) => {
                return (
                  <div
                    className={
                      chosenUser.id == w.id ? "d-user-name" : "d-user-blank"
                    }
                    onClick={() => setChosenUser(w)}
                  >
                    {w.fullName}
                  </div>
                );
              })}
          </div>
          <div className="flex-row-title">
            <div
              className="btn-save"
              onClick={() => {
                let aPlan = {};
                aPlan["allocation"] = "MANUAL";
                aPlan["category"] = clickedPPlan.category;
                aPlan["requiredSkill"] = clickedPPlan.skill;
                aPlan["user"] = { id: chosenUser.id };
                updateAllocation(aPlan, clickedPPlan.id);
              }}
            >
              Allocate
            </div>
            <div className="btn-cancel" onClick={() => setShowAddDiag(false)}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
