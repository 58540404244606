import "./Platform.css";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { GetApp, Upload } from "@mui/icons-material";
import { config } from "./../config";

function ManningNorms(props) {
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    console.log("Handle clicked");
    hiddenFileInput.current.click();
  };
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [mfgLines, setMfgLines] = useState([]);
  const [sites, setSites] = useState([]);
  const [plants, setPlants] = useState([]);
  const [areas, setAreas] = useState([]);
  const [mfgLine, setMfgLine] = useState();
  const [site, setSite] = useState();
  const [plant, setPlant] = useState();
  const [area, setArea] = useState();

  useEffect(() => {
    getItems("sites");
    getItems("plants");
    getItems("mfglines");
    getItems("areas");
    getMfgLine(JSON.parse(localStorage.getItem("user"))["department"]);
  }, []);

  function getMfgLine(mfgLine) {
    fetch(config.apiUrl + "mfglines/" + mfgLine + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched " + mfgLine + "!");
        setSite(actualData["plant"]["site"]["id"]);
        setPlant(actualData["plant"]["id"]);
        setMfgLine(actualData["id"]);
      })
      .catch((err) => {
        setMfgLine(null);
        console.log(err);
      });
  }

  function getItems(what) {
    fetch(config.apiUrl + what + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert(
          "green",
          "Fetched " + what.charAt(0).toUpperCase() + what.slice(1) + "!"
        );
        if (what === "sites") setSites(actualData);
        else if (what === "plants") setPlants(actualData);
        else if (what === "areas") setAreas(actualData);
        else setMfgLines(actualData);
      });
  }

  function getManningNormsForArea(area_id) {
    fetch(config.apiUrl + "manning-norms/" + area_id + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Fetched Manning Norms!");
        setData(actualData);
      });
  }

  function handleFileChange(e) {
    console.log("Handling file change");
    if (e.target.files) {
      const f = new FormData();
      f.append("file", e.target.files[0]);
      fetch(config.apiUrl + "manning-norms/upload/", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
        },
        body: f,
      }).then((response) => {
        if (response.ok) {
          props.raiseAlert("green", "Data Uploaded");
        }
      });
    }
  }

  function getTemplate() {
    console.log("Getting template");
    const a = document.createElement("a");
    a.href = config.apiUrl + "manning-norms/template/";
    a.download = (config.apiUrl + "manning-norms/template/").split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      // url.searchParams.set(
      //   "start",
      //   `${pagination.pageIndex * pagination.pageSize}`
      // );
      // url.searchParams.set("size", `${pagination.pageSize}`);
      // url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      // url.searchParams.set("globalFilter", globalFilter ?? "");
      // url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      try {
        // const response = await fetch(url.href);
        // const json = ;
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "work",
        header: "Product|Operation",
      },
      {
        accessorKey: "category",
        header: "Category",
      },
      {
        accessorKey: "skill",
        header: "Skill Level",
      },
      {
        accessorKey: "count",
        header: "Count",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableGrouping: true,
    initialState: {
      density: "compact",
    },
    enableStickyHeader: true,
    muiToolbarAlertBannerProps: {
      sx: {
        display: "none",
      },
    },
    enableEditing: true,
    editDisplayMode: "row",
    createDisplayMode: "row",
    positionCreatingRow: "bottom",

    onCreatingRowSave: ({ table, values }) => {
      //validate data
      //save data to api
      table.setCreatingRow(null); //exit creating mode
    },
    onCreatingRowCancel: () => {
      //clear any validation errors
    },
    onEditingRowSave: ({ table, values }) => {
      //validate data
      //save data to api
      console.log(values);
      table.setEditingRow(null); //exit editing mode
    },
    onEditingRowCancel: () => {
      //clear any validation errors
    },
  });

  return (
    <div className="f-dtl-container">
      <div
        className="created-row"
        style={{ width: "95%", margin: "2rem 0rem" }}
      >
        <div className="creation-cell" style={{ width: "20%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <option value={null}>Select a Site</option>
              {sites.map((site, inx) => {
                return (
                  <option key={inx} value={site.id}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <div className="creation-cell" style={{ width: "20%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={plant}
              onChange={(e) => setPlant(e.target.value)}
            >
              <option value={null}>Select a Plant</option>
              {plants
                .filter((plant) => plant.site.id == site)
                .map((plant, inx) => {
                  return (
                    <option key={inx} value={plant.id}>
                      {plant.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "20%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={mfgLine}
              onChange={(e) => setMfgLine(e.target.value)}
            >
              <option value={null}>Select a Mfg. Line</option>
              {mfgLines
                .filter((mfgLine) => mfgLine.plant.id == plant)
                .map((mfgLine, inx) => {
                  return (
                    <option key={inx} value={mfgLine.id}>
                      {mfgLine.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "20%" }}>
          <div className="cell-control" style={{ width: "100%" }}>
            <select
              type="text"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            >
              <option value={null}>Select an Area</option>
              {areas
                .filter((area) => area.mfgLine.id == mfgLine)
                .map((area, inx) => {
                  return (
                    <option key={inx} value={area.id}>
                      {area.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="creation-cell" style={{ width: "10%" }}>
          <div
            className="btn create-btn-n"
            onClick={() => {
              if (area != undefined && area != "") {
                getManningNormsForArea(area);
              } else {
                props.raiseAlert("red", "Please select an area!");
              }
            }}
          >
            Filter
          </div>
        </div>
      </div>
      <div className="f-table">
        <MaterialReactTable
          columns={columns}
          data={data}
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: "flex", gap: "1rem", p: ".4rem" }}>
              <Typography
                variant="h6"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "Poppins",
                  fontSize: "1.8rem",
                  alignSelf: "center",
                }}
              >
                Manning Norms
              </Typography>
              <div
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={getTemplate}
                className="create-btn-n"
              >
                Get Template
              </div>
              <div onClick={handleClick} className="create-btn-b">
                Upload
              </div>
              {/* <IconButton
                onClick={() => {
                  table.setCreatingRow(true);
                }}
              >
                <Add></Add>
              </IconButton> */}
            </Box>
          )}
          muiTableHeadCellFilterTextFieldProps={
            config.mrtStyle.muiTableHeadCellFilterTextFieldProps
          }
          muiTableContainerProps={config.mrtStyle.muiTableContainerProps}
          initialState={{
            density: "compact",
            columnVisibility: { id: false },
            pagination: {
              pageSize: 20,
              pageIndex: 0,
            },
          }}
          muiTableHeadCellColumnActionsButtonProps={
            config.mrtStyle.muiTableHeadCellColumnActionsButtonProps
          }
          muiTableHeadCellProps={config.mrtStyle.muiTableHeadCellProps}
          muiTableBodyCellProps={config.mrtStyle.muiTableBodyCellProps}
          muiTableBodyProps={config.mrtStyle.muiTableBodyProps}
          muiBottomToolbarProps={config.mrtStyle.muiBottomToolbarProps}
        />
      </div>
    </div>
  );
}

export default ManningNorms;
