import { useState } from "react";
import { config } from "../config";
import AuditMgmt from "./AuditMgmt";
import DepartmentMgmt from "./DepartmentMgmt";
import "./Platform.css";
import RoleMgmt from "./RoleMgmt";
import SettingsMgmt from "./SettingsMgmt";
import UserMgmt from "./UserMgmt";
import SiteHierarchy from "./SiteHierarchy";
import ManningNorms from "./ManningNorms";
import ProductionPlan from "./ProductionPlan";
import ProductMaster from "./ProductMaster";

function PlatformSetup(props) {
  const [itemClicked, setItemClicked] = useState("");

  return (
    <div className="dashboard-container">
      <div className="u-d-container">
        <div className="u-menu p-menu-sidebar">
          {(JSON.parse(localStorage.getItem("user")).role.includes(
            "ROLE_ADMIN"
          ) ||
            1 == 1) && (
            <>
              <div
                className={"u-menu-head p-menu "}
                onClick={() => setItemClicked("user")}
              >
                <div
                  className={
                    itemClicked === "user"
                      ? "selected-menu-head"
                      : "unselected-menu-head"
                  }
                >
                  <i className="fa-solid fa-users"></i>
                </div>
                <div style={{ fontWeight: "bold" }}>Users</div>
              </div>
              <div
                className={"u-menu-head p-menu "}
                onClick={() => setItemClicked("role")}
              >
                <div
                  className={
                    itemClicked === "role"
                      ? "selected-menu-head"
                      : "unselected-menu-head"
                  }
                >
                  <i className="fa-solid fa-universal-access"></i>
                </div>
                <div style={{ fontWeight: "bold" }}>Roles</div>
              </div>
              <div
                className={"u-menu-head p-menu "}
                onClick={() => setItemClicked("department")}
              >
                <div
                  className={
                    itemClicked === "department"
                      ? "selected-menu-head"
                      : "unselected-menu-head"
                  }
                >
                  <i className="fa-solid fa-building"></i>
                </div>
                <div style={{ fontWeight: "bold" }}>Departments</div>
              </div>
              <div
                className={"u-menu-head p-menu "}
                onClick={() => setItemClicked("audit")}
              >
                <div
                  className={
                    itemClicked === "audit"
                      ? "selected-menu-head"
                      : "unselected-menu-head"
                  }
                >
                  <i className="fa-solid fa-file-lines"></i>
                </div>
                <div style={{ fontWeight: "bold" }}>Audit</div>
              </div>
              <div
                className={"u-menu-head p-menu "}
                onClick={() => setItemClicked("settings")}
              >
                <div
                  className={
                    itemClicked === "settings"
                      ? "selected-menu-head"
                      : "unselected-menu-head"
                  }
                >
                  <i className="fa-solid fa-gears"></i>
                </div>
                <div style={{ fontWeight: "bold" }}>Settings</div>
              </div>
            </>
          )}
        </div>
        {itemClicked === "department" && (
          <DepartmentMgmt raiseAlert={props.raiseAlert}></DepartmentMgmt>
        )}
        {itemClicked === "role" && (
          <RoleMgmt raiseAlert={props.raiseAlert}></RoleMgmt>
        )}
        {itemClicked === "user" && (
          <UserMgmt raiseAlert={props.raiseAlert}></UserMgmt>
        )}
        {itemClicked === "audit" && (
          <AuditMgmt raiseAlert={props.raiseAlert}></AuditMgmt>
        )}
        {itemClicked === "settings" && (
          <SettingsMgmt raiseAlert={props.raiseAlert}></SettingsMgmt>
        )}
      </div>
    </div>
  );
}

export default PlatformSetup;
