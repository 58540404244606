import "./Platform.css";
import { config } from "../config";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";

function QualificationEdit(props) {
  console.log(props);
  const [user, setUser] = useState(props.user);
  const [users, setUsers] = useState(props.users);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    // getUsers();
    getAreas();
  }, []);

  function getAreas() {
    fetch(config.apiUrl + "areas/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        // props.raiseAlert("green", "Fetched Users!");
        console.log(actualData);
        setAreas(actualData);
      });
  }

  function getUsers() {
    fetch(config.apiUrl + "users/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        // props.raiseAlert("green", "Fetched Users!");
        setUsers(actualData);
      });
  }

  function userChanged(what, value) {
    setUser((prev) => {
      let toBeUpdated = { ...prev };
      if (what !== "final") {
        var obj = toBeUpdated;
        let splitWhat = what.split(".");
        for (var i = 0; i < splitWhat.length - 1; i++) {
          let prop = splitWhat[i];
          obj = obj[prop];
        }
        let finalProp = splitWhat[i];
        obj[finalProp] = value;
      }
      return toBeUpdated;
    });
  }

  function updateUserQualification() {
    let updatedUser = {};
    updatedUser["id"] = user["id"];
    updatedUser["area"] = user["areaIds"].split(",").map((a) => {
      return { id: a };
    });
    fetch(config.apiUrl + "users/qualification/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("access")).access_token,
      },
      body: JSON.stringify(updatedUser),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((actualData) => {
        props.raiseAlert("green", "Skill Matrix updated!");
        props.closeWindow(false);
      });
  }
  return (
    <div className="user-preview">
      <div className="viz-preview-details">
        <div className="viz-name">Edit Skill Matrix</div>
        <div className="grow"></div>
        <div className="close-icon">
          <i
            className="fa-solid fa-close"
            onClick={() => props.closeWindow(false)}
          ></i>
        </div>
      </div>
      {areas.length > 0 && (
        <div className="created-container">
          <div className="created-row">
            <div className="creation-cell" style={{ width: "100%" }}>
              <div className="cell-name">
                <div>User</div>
              </div>
              <div className="cell-control">
                <Multiselect
                  style={config.multiSelectStyle}
                  avoidHighlightFirstOption={true}
                  onSelect={(e) => {
                    userChanged("id", e[0].id);
                  }}
                  onRemove={(e) => userChanged("id", "")}
                  selectedValues={users.filter((eq) => user["id"] == eq["id"])}
                  options={users}
                  displayValue={"fullName"}
                  selectionLimit={1}
                  closeOnSelect={true}
                  hidePlaceholder={true}
                  disable={
                    props.user.id != undefined && props.user.id.length > 0
                  }
                ></Multiselect>
              </div>
            </div>
          </div>
          <div className="created-row">
            <div className="creation-cell-auto" style={{ width: "100%" }}>
              <div className="cell-name">
                <div>Areas</div>
              </div>
              <div className="cell-control">
                <Multiselect
                  style={config.multiSelectAutoHeightStyle}
                  avoidHighlightFirstOption={true}
                  onSelect={(e) => {
                    userChanged("areaIds", e.map((e) => e.id).join(","));
                  }}
                  onRemove={(e) =>
                    userChanged("areaIds", e.map((e) => e.id).join(","))
                  }
                  selectedValues={areas.filter((eq) =>
                    user["areaIds"].split(",").includes(eq["id"] + "")
                  )}
                  options={areas}
                  displayValue={"name"}
                ></Multiselect>
              </div>
            </div>
          </div>
          <div className="btn-controls">
            <div
              onClick={updateUserQualification}
              className="create-btn margin-top-10"
            >
              {user.id == undefined ? "Add" : "Update"}
            </div>

            <div
              className="cancel-btn"
              onClick={() => props.closeWindow(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QualificationEdit;
